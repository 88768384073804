import {
  SET_LOGIN_CREDENTIALS,
  CLEAR_LOGIN,
  SET_LOADING,
  CLEAR_LOADING,
  SET_ROLES_LIST,
  SET_SELECTED_UCN,
  CLEAR_SELECTED_UCN,
  SET_USER_ROLE,
  CLEAR_USER_ROLE,
  SET_PAGE
} from "./constants";

export const setLogin = (data) => {
  return {
    type: SET_LOGIN_CREDENTIALS,
    payload: data,
  };
};

export const clearLogin = () => {
  return {
    type: CLEAR_LOGIN,
  };
};

export const setAllRoles = (data) => {
  return {
    type: SET_ROLES_LIST,
    payload: data,
  };
};

export const setLoading = () => {
  return {
    type: SET_LOADING,
  };
};

export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  };
};

export const setDashboardSelectedUCN = (ucn) => {
  return {
    type: SET_SELECTED_UCN,
    payload: ucn,
  };
};
export const clearDashboardSelectedUCN = () => {
  return {
    type: CLEAR_SELECTED_UCN,
  };
};

export const setUserRole = (payload) => {
  return {
    type: SET_USER_ROLE,
    payload,
  };
};

export const clearUserRole = () => {
  return {
    type: CLEAR_USER_ROLE,
  };
};

export const setPage = (page) => {
  return {
    type: SET_PAGE,
    payload: page,
  };
};
