import React, { useEffect, useState, useRef } from 'react'
import styles from '../styles/DIS.module.css'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRightFromBracket,
  faTriangleExclamation
} from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { msalFetch } from '../utilities/msalFetch'
import { Alert } from 'antd'
import { debounceAPI } from '../utilities/debounce'

const Phlebotomist = props => {
  const [formData, setFormdata] = useState({ donor_id: props.donorId })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const [edit, setEdit] = useState(false)
  const [formDisable, setFormDisable] = useState(false)
  const [speSampleTaken, setSpeSampleTaken] = useState(false)
  const [setupDoneBy, setSetUpDoneBy] = useState(null)
  const [restickDoneBy, setRestickDoneBy] = useState(null)
  const [disconnectDoneBy, setDisconnectDoneBy] = useState(null)
  const [phlebotomyStatus, setPhlebotomyStatus] = useState({})

  useEffect(() => {
    props.actions.setLoading()
    if (sessionStorage.getItem('selectedUCN')) {
      setEdit(false)
    }
  }, [])

  const getPhylebotomyStatus = ucn => {
    props.actions.setLoading()
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    msalFetch(`/api/donor/getPhlebotomyStatus/${ucn}`, options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          const data = await res.json()
          setPhlebotomyStatus(data)
        } else {
          setPhlebotomyStatus({})
        }
      })
      .catch(e => {
        props.actions.clearLoading()
      })
  }

  useEffect(() => {
    if (props.drd && props.drd.donor_id) {
      props.actions.clearLoading()
      setLoading(false)
      setFormdata(props.drd)
      getPhylebotomyStatus(props.drd.ucn)
      setSpeSampleTaken(Boolean(props.drd.spe_taken))
      if (props.drd.status == 'QA Reviewed') {
        setEdit(false)
        setFormDisable(true)
      } else {
        if (props.drd.machine_number) {
          setEdit(true)
        } else {
          setEdit(false)
        }
      }
    } else {
      if (props.drd && !props.drd.donor_id) {
        setLoading(false)
        props.actions.clearLoading()
      }
    }
  }, [props.drd])

  const saveSetupVenipunctureTime = () => {
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({ ucn: props.drd?.ucn, flag: 'setup_time' })
    }
    msalFetch('api/donor/savePhlebotomyTimeStamps', options)
      .then(async res => {})
      .catch(er => console.log(er))
  }

  const handleChange = e => {
    setErrors({})
    let data = { ...formData }
    if (e.target.type !== 'radio') {
      let value =
        e.target.name === 'plasma_amt_machine' ||
        e.target.name === 'collection_amt_machine'
          ? parseInt(e.target.value)
          : e.target.value
      data[e.target.name] = value
      if (e.target.name === 'plasma_amt_machine') {
        setDisconnectDoneBy(`${props.displayName}`)
        const plasma_diff = parseInt(e.target.value) - formData.plasma_amt
        if (plasma_diff > 5) {
          data.alert_flags = 'OVERDRAW'
        } else if (plasma_diff < -5) {
          data.alert_flags = 'UNDERDRAW'
        } else {
          data.alert_flags = ''
          if (data.alert_flag_reason) data.alert_flag_reason = null
        }
      }
      if (
        e.target.name == 'alert_flag' &&
        (e.target.value !== 'UNDERDRAW' || e.target.value == 'OVERDRAW')
      ) {
        data.phlebotomy_comments = null
      }
      if (e.target.name == 'alert_flag_reason' && e.target.value !== 'Others') {
        data.phlebotomy_comments = null
      }
      setFormdata({ ...data })
    } else {
      if (e.target.name == 'arm_used') {
        saveSetupVenipunctureTime()
        setSetUpDoneBy(`${props.displayName}`)
        if (e.target.checked) {
          setFormdata({ ...formData, arm_used: e.target.value })
        } else {
          setFormdata({ ...formData, arm_used: null })
        }
      } else if (e.target.name == 'arm_used_restick') {
        setRestickDoneBy(`${props.displayName}`)
        if (e.target.checked) {
          setFormdata({ ...formData, arm_used_restick: e.target.value })
        } else {
          setFormdata({ ...formData, arm_used_restick: null })
        }
      } else if (e.target.name == 'spe_taken') {
        setFormdata({ ...formData, [e.target.name]: Boolean(e.target.value) })
      }
    }
  }

  const handleSPESampleTaken = e => {
    if (e.target.checked) {
      setSpeSampleTaken(true)
    } else {
      setSpeSampleTaken(false)
    }
  }

  const exitButtonHandler = e => {
    e.preventDefault()
    if (props.drd?.status != 'QA Reviewed') {
      setEdit(true)
    } else {
      history.push('/dashboard')
    }
  }

  const validation = () => {
    let validationErrors = {}
    if (formData.machine_number == undefined || formData.machine_number == '') {
      validationErrors.machine_number = 'Machine Number cannot be blank'
    }
    if (!formData.arm_used?.length) {
      validationErrors.armused = 'Please check Arm used'
    }
    if (
      formData.plasma_amt_machine == undefined ||
      formData.plasma_amt_machine == 0 ||
      formData.plasma_amt_machine == ''
    ) {
      validationErrors.plasma_amt = 'Plasma Volume cannot be 0'
    }
    if (
      formData.collection_amt_machine == undefined ||
      formData.collection_amt_machine == 0 ||
      formData.collection_amt_machine == ''
    ) {
      validationErrors.collection_amt =
        'Total Plasma collection volume cannot be 0'
    }
    if (
      formData.anticoagulant_lot_number == undefined ||
      formData.anticoagulant_lot_number == ''
    ) {
      validationErrors.anticoagulant = 'Please enter Anticoagulant lot number.'
    }
    if (
      formData.saline_lot_number == undefined ||
      formData.saline_lot_number == ''
    ) {
      validationErrors.saline = 'Please enter Saline lot number.'
    }
    if (
      formData.bowl_lot_number == undefined ||
      formData.bowl_lot_number == ''
    ) {
      validationErrors.bowl = 'Please enter Bowl lot number.'
    }
    if (
      formData.bottle_lot_number == undefined ||
      formData.bottle_lot_number == ''
    ) {
      validationErrors.bottle = 'Please enter Bottle lot number.'
    }
    if (
      formData.harness_lot_number == undefined ||
      formData.harness_lot_number == ''
    ) {
      validationErrors.harness = 'Please enter Harness lot number.'
    }
    if (
      (formData.alert_flags == 'UNDERDRAW' ||
        formData.alert_flags == 'OVERDRAW') &&
      (formData.alert_flag_reason == undefined ||
        formData.alert_flag_reason == '' ||
        formData.alert_flag_reason == null)
    ) {
      validationErrors.alert_flag_reason = 'Please select reason'
    }
    setErrors({ ...validationErrors })
    if (Object.keys(validationErrors).length == 0) {
      return true
    } else {
      return false
    }
  }

  const handleCompleteDonation = e => {
    e.preventDefault()
    if (validation()) {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
      props.actions.setLoading()
      const data = {
        formData: {
          arm_used: formData.arm_used,
          arm_used_restick: formData.arm_used_restick,
          machine_number: formData.machine_number,
          plasma_amt_machine: formData.plasma_amt_machine,
          donor_id: formData.donor_id,
          ucn: formData.ucn,
          collection_amt_machine: formData.collection_amt_machine,
          anticoagulant_lot_number: formData.anticoagulant_lot_number,
          saline_lot_number: formData.saline_lot_number,
          bowl_lot_number: formData.bowl_lot_number,
          bottle_lot_number: formData.bottle_lot_number,
          harness_lot_number: formData.harness_lot_number,
          alert_flags: formData.alert_flags ? formData.alert_flags : null,
          alert_flag_reason: formData.alert_flag_reason
            ? formData.alert_flag_reason
            : null,
          phlebotomy_comments: formData.phlebotomy_comments
            ? formData.phlebotomy_comments
            : null,
          reaction: formData.reaction ? formData.reaction : null,
          rbc_loss: formData.rbc_loss ? formData.rbc_loss : null
        },
        role: props.roleid,
        speSampleTaken,
        completeDonation: true,
        disconnectDoneBy,
        setupDoneBy,
        restickDoneBy
      }
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(data)
      }
      msalFetch('api/donor/saveDRD', options)
        .then(async res => {
          const data = await res.json()
          //props.actions.clearLoading();
          if (res.status == 200) {
            toast.success('Donation Completed Successfully')
            props.actions.clearLoading()
            setEdit(true)
            let donorId = props.donorInfo.ssn.replaceAll('-', '')
            donorId = donorId.slice(-5)
            //setEdit(true)
            const body = {
              ucn: formData.ucn
            }
            const options = {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                Authorization: props.token
              },
              body: JSON.stringify(body)
            }
            msalFetch('/api/donor/postDonationToCDCS', options)
              .then(async response => {
                const data = await response.json()
                if (response.status == 200) {
                  toast.success('Donation Logged into CDCS Successfully')
                } else {
                  toast.error(data.message)
                }
              })
              .catch(error =>
                toast.error('Failed to log Donation into CDCS - Network Error')
              )
          } else {
            toast.error(data.message)
          }
        })
        .catch(err => props.actions.clearLoading())
    }
  }

  const validateSaveDonation = () => {
    let validationErrors = {}
    if (!formData.machine_number) {
      validationErrors = {
        ...validationErrors,
        machine_number: 'Machine Number is required.'
      }
    }
    if (!formData.arm_used?.length) {
      validationErrors.armused = 'Please select Arm used'
    }
    if (
      formData.anticoagulant_lot_number == undefined ||
      formData.anticoagulant_lot_number == ''
    ) {
      validationErrors.anticoagulant = 'Please enter Anticoagulant lot number.'
    }
    if (
      formData.saline_lot_number == undefined ||
      formData.saline_lot_number == ''
    ) {
      validationErrors.saline = 'Please enter Saline lot number.'
    }
    if (
      formData.bowl_lot_number == undefined ||
      formData.bowl_lot_number == ''
    ) {
      validationErrors.bowl = 'Please enter Bowl lot number.'
    }
    if (
      formData.bottle_lot_number == undefined ||
      formData.bottle_lot_number == ''
    ) {
      validationErrors.bottle = 'Please enter Bottle lot number.'
    }
    if (
      formData.harness_lot_number == undefined ||
      formData.harness_lot_number == ''
    ) {
      validationErrors.harness = 'Please enter Harness lot number.'
    }
    setErrors({ ...validationErrors })
    if (Object.keys(validationErrors).length) {
      return false
    }
    return true
  }

  const handleSave = e => {
    e.preventDefault()
    if (validateSaveDonation()) {
      props.actions.setLoading()
      const data = {
        formData: {
          arm_used: formData.arm_used,
          arm_used_restick: formData.arm_used_restick,
          machine_number: formData.machine_number,
          plasma_amt_machine: formData.plasma_amt_machine,
          donor_id: formData.donor_id,
          ucn: formData.ucn,
          collection_amt_machine: formData.collection_amt_machine,
          anticoagulant_lot_number: formData.anticoagulant_lot_number,
          saline_lot_number: formData.saline_lot_number,
          bowl_lot_number: formData.bowl_lot_number,
          bottle_lot_number: formData.bottle_lot_number,
          harness_lot_number: formData.harness_lot_number,
          alert_flags: formData.alert_flags ? formData.alert_flags : null,
          alert_flag_reason: formData.alert_flag_reason
            ? formData.alert_flag_reason
            : null,
          phlebotomy_comments: formData.phlebotomy_comments
            ? formData.phlebotomy_comments
            : null,
          reaction: formData.reaction ? formData.reaction : null,
          rbc_loss: formData.rbc_loss ? formData.rbc_loss : null
        },
        role: props.roleid,
        speSampleTaken,
        completeDonation: false,
        restickDoneBy,
        setupDoneBy,
        disconnectDoneBy
      }
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(data)
      }
      msalFetch('api/donor/saveDRD', options)
        .then(async res => {
          const data = await res.json()
          props.actions.clearLoading()
          if (res.status == 200) {
            toast.success('Donation saved Successfully')
            history.push('/dashboard')
          }
        })
        .catch(err => {
          console.error(err)
          props.actions.clearLoading()
          toast.error('Error while saving donation')
        })
    }
  }

  const validateSPEOnlyData = () => {
    if (formData.spe_taken == null) {
      setErrors({ ...errors, spe_taken: 'Please select SPE Taken' })
      return false
    } else if (!formData.phlebotomy_comments) {
      setErrors({ ...errors, phlebotomy_comments: 'Please enter comments' })
      return false
    } else {
      setErrors({})
      return true
    }
  }

  const saveSpeOnlyDonation = () => {
    if (validateSPEOnlyData()) {
      props.actions.setLoading()
      const data = {
        ucn: formData.ucn,
        spe_taken: formData.spe_taken,
        phlebotomy_comments: formData.phlebotomy_comments,
        page: 'phlebotomy',
        donor_id: formData.donor_id
      }
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
      props.actions.setLoading()
      msalFetch('api/donor/saveSpeSampleOnlyDonation', options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            sessionStorage.setItem('selectedUCN', formData.ucn)
            const data = await res.json()
            toast.success(data.message)
            history.push('/dashboard')
          } else {
            const data = await res.json()
            toast.error(data.message)
          }
        })
        .catch(err => {
          props.actions.clearLoading()
          toast.error(err.message)
        })
    }
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className='row mt-5 justify-content-center'>
          <div className='col-md-6 text-center'>Loading....</div>
        </div>
      )
    } else if (!props.drd.ucn) {
      return (
        <div className='row mt-5 mb-4 justify-content-center'>
          <div className='col-md-6'>
            <div className='card'>
              <div className='card-body'>
                <h6 className='card-title'>
                  No active Donation for this Donor
                </h6>
                <button
                  style={{
                    marginRight: '16px',
                    width: '80px',
                    height: '30px',
                    verticalAlign: 'center',
                    padding: '0',
                    borderRadius: '10px'
                  }}
                  className='btn btn-outline-danger mr-3'
                  onClick={() => history.push('/dashboard')}
                >
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    style={{ marginRight: '5px' }}
                    onClick={() => history.push('/dashboard')}
                  />
                  Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (props.drd.spe_only) {
      return (
        <div className='row justify-content-center mt-4 d-flex mb-4'>
          <div className='col-md-6'>
            <h4 className='text-start'>SPE Only Donation</h4>
            <div className='form-group row align-items-center'>
              <label
                htmlFor='UCN'
                className={`col-sm-3 col-form-label ${styles.drdLabel}`}
              >
                UCN <span style={{ color: 'red' }}>&#42;</span> :
              </label>
              <div className='col-sm-6'>
                <input
                  type='text'
                  className='form-control form-control-sm'
                  id='UCN'
                  name='ucn'
                  placeholder='UCN'
                  value={props?.drd?.ucn}
                  pattern='LP\d{8}'
                  disabled={true}
                />
              </div>
            </div>
            <div className='form-group row align-items-center'>
              <label
                htmlFor='spe_taken'
                className={`col-sm-3 col-form-label ${styles.drdLabel} d-flex justify-content-start`}
              >
                SPE Sample Taken<span style={{ color: 'red' }}>&#42;</span>:
              </label>
              <div className='col-sm-6 d-flex justify-content-start'>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='spe_taken'
                    id='spe_taken_yes'
                    value='1'
                    onChange={handleChange}
                    disabled={formDisable}
                    defaultChecked={formData.spe_taken == 1}
                  />
                  <label class='form-check-label' htmlFor='spe_taken_yes'>
                    Yes
                  </label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='spe_taken'
                    id='spe_taken_no'
                    value='0'
                    defaultChecked={formData.spe_taken == 0}
                    onChange={handleChange}
                    disabled={formDisable}
                  />
                  <label class='form-check-label' htmlFor='spe_taken_no'>
                    No
                  </label>
                </div>
                {errors.spe_taken ? (
                  <p className='text-danger'>{errors.spe_taken}</p>
                ) : null}
              </div>
            </div>
            <div className='form-group row align-items-center'>
              <label
                htmlFor='spe_taken'
                className={`col-sm-3 col-form-label ${styles.drdLabel} d-flex justify-content-start`}
              >
                Comments<span style={{ color: 'red' }}>&#42;</span>:
              </label>
              <div className='col-sm-5 d-flex justify-content-start'>
                <textarea
                  className='form-control'
                  id='phlebotomy_comments'
                  rows='3'
                  name='phlebotomy_comments'
                  onChange={handleChange}
                  disabled={formDisable}
                  value={formData.phlebotomy_comments}
                ></textarea>
                {errors.phlebotomy_comments ? (
                  <span className={styles.validationError}>
                    {errors.phlebotomy_comments}
                  </span>
                ) : null}
              </div>
            </div>
            <div className='form-group row mt-4'>
              <div className='col-md-1'>
                <Button
                  type='primary'
                  size='small'
                  onClick={saveSpeOnlyDonation}
                  disabled={formDisable}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (
      props.drd.status === 'Pending' &&
      props.donorInfo.donor_status !== 'ACTIVE'
    ) {
      return (
        <>
          <div className='row mt-4 d-flex justify-content-center'>
            <div className='col-md-6 card'>
              <div className='card-body'>
                <h5 className='card-title'>
                  Donor does not meet basic eligibility criteria to Donate
                </h5>
                <p className='card-text text-danger'>
                  {`Donor Status is ${props.donorInfo.donor_status}`}
                </p>
                <button
                  style={{
                    marginRight: '16px',
                    width: '80px',
                    height: '30px',
                    verticalAlign: 'center',
                    padding: '0',
                    borderRadius: '10px'
                  }}
                  className='btn btn-outline-danger mr-3'
                  onClick={() => history.push('/dashboard')}
                >
                  Exit
                </button>
              </div>
            </div>
          </div>
        </>
      )
    } else if (props.drd.no_donation) {
      return (
        <div row justify-content-center mt-4 mb-5>
          <div className='col-md-4 offset-4 mt-4'>
            <Alert
              showIcon
              message={props.drd.ucn}
              description='This UCN is marked as No Donation'
              type='info'
            ></Alert>
          </div>
        </div>
      )
    } else if (
      props.drd.status === 'Pending' &&
      (!props.drd.hct || !props.drd.weight)
    ) {
      return (
        <div row justify-content-center mt-4 mb-5>
          <div className='col-md-4 offset-4 mt-4'>
            <Alert
              showIcon
              message={props.drd.ucn}
              description='Donor has not completed Medical Screening'
              type='info'
            ></Alert>
          </div>
        </div>
      )
    } else if (edit) {
      return (
        <div className='row justify-content-center mt-4 d-flex mb-5'>
          <div className='col-md-6'>
            <div className='card'>
              <div className='card-header'>Donation ID : {props.drd?.ucn}</div>
              <div className='card-body' style={{ fontSize: '14px' }}>
                <table className='table table-sm table-bordered'>
                  <tbody>
                    <tr>
                      <th scope='row'>Plasma Volume</th>
                      <td>{formData.plasma_amt_machine}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Total Collection Volume</th>
                      <td>{formData.collection_amt_machine}</td>
                    </tr>
                    <tr>
                      <th scope='row'>Arm Used</th>
                      <td>
                        {formData.arm_used == 'L'
                          ? 'Left'
                          : props.drd?.arm_used == 'R'
                          ? 'Right'
                          : 'Both'}
                      </td>
                    </tr>
                    <tr>
                      <th scope='row'>Machine Number</th>
                      <td>{formData.machine_number}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='card-footer d-flex'>
                <button
                  className='btn btn-outline-dark'
                  style={{
                    width: '100px',
                    height: '35px',
                    padding: '0',
                    marginRight: '20px'
                  }}
                  onClick={() => setEdit(false)}
                >
                  Edit
                </button>
                <button
                  className='btn btn-danger'
                  style={{ width: '100px', height: '35px', padding: 0 }}
                  onClick={() => {
                    history.push('/dashboard')
                  }}
                >
                  Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='row justify-content-end mt-5 d-flex mb-5'>
          <div className='col-sm-6'>
            {formData.weight_lost &&
            formData.weight_lost !== 0 &&
            formData.weight_lost > 10 ? (
              props.healthAssessment?.STATUS &&
              props.healthAssessment.STATUS === 'Pending' ? (
                <Alert
                  message={`! Donor has lost ${formData.weight_lost} lbs since last donation`}
                  type='error'
                />
              ) : null
            ) : null}
            <form onSubmit={handleCompleteDonation}>
              <fieldset>
                <legend>Setup/Connect</legend>
                <div className='form-group row'>
                  <label
                    htmlFor='machine_number'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    UCN :
                  </label>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='machine_number'
                      placeholder='UCN'
                      value={formData.ucn}
                      name='machine_number'
                      disabled
                    />
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='plasmaamt'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Target Plasma Volume (mL):
                  </label>
                  <div className='col-sm-6'>
                    <input
                      type='number'
                      className='form-control form-control-sm'
                      id='plasmaamt'
                      placeholder='Target Plasma Volume'
                      value={props.drd.plasma_amt}
                      name='plasma_amt'
                      disabled
                    />
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='collection_amount'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Target Total Collection Volume (mL):
                  </label>
                  <div className='col-sm-6'>
                    <input
                      type='number'
                      className='form-control form-control-sm'
                      id='collection_amount'
                      placeholder='Target Total Collection Volume'
                      value={props.drd.collection_amt}
                      name='collection_amt'
                      disabled
                    />
                  </div>
                </div>

                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='machine_number'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Machine Number <span style={{ color: 'red' }}>&#42;</span> :
                  </label>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='machine_number'
                      placeholder='Machine Number'
                      value={formData.machine_number}
                      name='machine_number'
                      onChange={handleChange}
                      disabled={formDisable}
                    />
                    {errors.machine_number != undefined ? (
                      <span className={styles.validationError}>
                        {errors.machine_number}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='arm_used'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Arm Used <span style={{ color: 'red' }}>&#42;</span> :
                  </label>
                  <div className='col-sm-2 d-flex justify-content-between'>
                    <label className='form-check-label' htmlFor='arm_usedl'>
                      L&nbsp;&nbsp;
                      <input
                        className='form-check-input'
                        type='radio'
                        value='L'
                        name='arm_used'
                        id='arm_usedl'
                        defaultChecked={formData.arm_used === 'L'}
                        onChange={handleChange}
                        disabled={formDisable}
                      />
                    </label>
                    <label className='form-check-label' htmlFor='arm_usedr'>
                      R&nbsp;&nbsp;
                      <input
                        className='form-check-input'
                        type='radio'
                        value='R'
                        name='arm_used'
                        id='arm_usedr'
                        defaultChecked={formData.arm_used === 'R'}
                        onChange={handleChange}
                        disabled={formDisable}
                      />
                    </label>
                  </div>
                  <div className='col-md-6 offset-5 mt-0 pt-0'>
                    {errors.armused != undefined ? (
                      <span className={styles.validationError}>
                        {errors.armused}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='Anticoagulant'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Anticoagulant Lot Number{' '}
                    <span style={{ color: 'red' }}>&#42;</span>:
                  </label>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='Anticoagulant'
                      placeholder='Anticoagulant Lot #'
                      name='anticoagulant_lot_number'
                      value={formData.anticoagulant_lot_number}
                      onChange={handleChange}
                      disabled={formDisable}
                    />
                    {errors.anticoagulant != undefined ? (
                      <span className={styles.validationError}>
                        {errors.anticoagulant}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='Saline'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Saline Lot Number{' '}
                    <span style={{ color: 'red' }}>&#42;</span>:
                  </label>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='Saline'
                      placeholder='Saline Lot #'
                      name='saline_lot_number'
                      value={formData.saline_lot_number}
                      onChange={handleChange}
                      disabled={formDisable}
                    />
                    {errors.saline != undefined ? (
                      <span className={styles.validationError}>
                        {errors.saline}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='bowl'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Bowl Lot Number <span style={{ color: 'red' }}>&#42;</span>:
                  </label>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='bowl'
                      placeholder='Bowl Lot #'
                      name='bowl_lot_number'
                      value={formData.bowl_lot_number}
                      onChange={handleChange}
                      disabled={formDisable}
                    />
                    {errors.bowl != undefined ? (
                      <span className={styles.validationError}>
                        {errors.bowl}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='bottle'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Bottle Lot Number{' '}
                    <span style={{ color: 'red' }}>&#42;</span>:
                  </label>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='bottle'
                      placeholder='Bottle Lot #'
                      name='bottle_lot_number'
                      value={formData.bottle_lot_number}
                      onChange={handleChange}
                      disabled={formDisable}
                    />
                    {errors.bottle != undefined ? (
                      <span className={styles.validationError}>
                        {errors.bottle}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='harness'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Harness Lot Number{' '}
                    <span style={{ color: 'red' }}>&#42;</span>:
                  </label>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='harness'
                      placeholder='Harness Lot #'
                      name='harness_lot_number'
                      value={formData.harness_lot_number}
                      onChange={handleChange}
                      disabled={formDisable}
                    />
                    {errors.harness != undefined ? (
                      <span className={styles.validationError}>
                        {errors.harness}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='spe_taken'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    SPE Sample Taken:
                  </label>
                  <div className='col-sm-6'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='spe_taken'
                      id='spe_taken'
                      onChange={handleSPESampleTaken}
                      defaultChecked={formData.spe_taken}
                      disabled={formDisable}
                    />
                    {props.speDue ? (
                      <p
                        className='text-danger'
                        style={{
                          fontSize: '14px',
                          padding: '0',
                          margin: '0'
                        }}
                      >
                        ! SPE due for this Donor.
                      </p>
                    ) : null}
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Restick (Optional)</legend>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='arm_used_restick'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Arm Used :
                  </label>
                  <div className='col-sm-2 d-flex justify-content-between'>
                    <label
                      className='form-check-label'
                      htmlFor='arm_usedl_restick'
                    >
                      L&nbsp;&nbsp;
                      <input
                        className='form-check-input'
                        type='radio'
                        value='L'
                        name='arm_used_restick'
                        id='arm_usedl_restick'
                        checked={formData.arm_used_restick === 'L'}
                        onChange={handleChange}
                        disabled={formDisable || formData?.arm_used === 'L'}
                      />
                    </label>
                    <label
                      className='form-check-label'
                      htmlFor='arm_usedr_restick'
                    >
                      R&nbsp;&nbsp;
                      <input
                        className='form-check-input'
                        type='radio'
                        value='R'
                        name='arm_used_restick'
                        id='arm_usedr_restick'
                        checked={formData.arm_used_restick === 'R'}
                        onChange={handleChange}
                        disabled={formDisable || formData?.arm_used === 'R'}
                      />
                    </label>
                  </div>
                  <div className='col-md-6 offset-5 mt-0 pt-0'>
                    {errors.armused_restick != undefined ? (
                      <span className={styles.validationError}>
                        {errors.arm_used_restick}
                      </span>
                    ) : null}
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Disconnect</legend>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='plasmaamt_machine'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Reaction:{' '}
                  </label>
                  <div className='col-sm-6'>
                    <select
                      className='form-select form-select-sm'
                      id='reaction'
                      name='reaction'
                      onChange={handleChange}
                      value={formData.reaction ? formData.reaction : ''}
                      disabled={formDisable}
                    >
                      <option selected value=''>
                        None
                      </option>
                      <option value={'Minor'}>
                        {'Minor (Phlebotomist Resolved)'}
                      </option>
                      <option value={'Moderate'}>
                        {'Moderate (Required Physician Substitute)'}
                      </option>
                      <option value={'Severe'}>
                        {'Severe (Required Physician Substitute and/or 911)'}
                      </option>
                    </select>
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='rbc_loss'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    RBC Loss:{' '}
                  </label>
                  <div className='col-sm-6'>
                    <select
                      className='form-select form-select-sm'
                      id='rbc_loss'
                      name='rbc_loss'
                      onChange={handleChange}
                      value={formData.rbc_loss ? formData.rbc_loss : ''}
                      disabled={formDisable}
                    >
                      <option selected value=''>
                        None
                      </option>
                      <option value={'L200RBCL'}>{'<200 mL RBC Loss'}</option>
                      <option value={'GE200RBCL'}>{'>=200 mL RBC Loss'}</option>
                    </select>
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='plasmaamt_machine'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Actual Plasma Volume (mL){' '}
                    <span style={{ color: 'red' }}>&#42;</span>:
                  </label>
                  <div className='col-sm-6'>
                    <input
                      type='number'
                      className='form-control form-control-sm'
                      id='plasmaamt_machine'
                      placeholder='Actual Plasma Volume(Machine Reading)'
                      name='plasma_amt_machine'
                      value={formData.plasma_amt_machine}
                      onChange={handleChange}
                      disabled={formDisable}
                    />
                    {errors.plasma_amt != undefined ? (
                      <span className={styles.validationError}>
                        {errors.plasma_amt}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='collection_amount_machine'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Actual Total Collection Volume (mL){' '}
                    <span style={{ color: 'red' }}>&#42;</span>:
                  </label>
                  <div className='col-sm-6'>
                    <input
                      type='number'
                      className='form-control form-control-sm'
                      id='collection_amount_machine'
                      placeholder='Actual Collection Volume'
                      name='collection_amt_machine'
                      value={formData.collection_amt_machine}
                      onChange={handleChange}
                      disabled={formDisable}
                    />
                    {errors.collection_amt != undefined ? (
                      <span className={styles.validationError}>
                        {errors.collection_amt}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className='form-group row'>
                  <label
                    htmlFor='alert_flags'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Alert Flag(optional) :
                  </label>
                  <div className='col-sm-6'>
                    <select
                      className='form-select form-select-sm'
                      id='alert_flags'
                      name='alert_flags'
                      onChange={handleChange}
                      value={formData.alert_flags ? formData.alert_flags : ''}
                      disabled={formDisable}
                    >
                      <option selected value=''>
                        None
                      </option>

                      <option value={'UNDERDRAW'}>UNDERDRAW</option>
                      <option value={'OVERDRAW'}>OVERDRAW</option>
                    </select>
                  </div>
                </div>
                {formData.alert_flags == 'UNDERDRAW' && (
                  <div className='form-group row'>
                    <label
                      htmlFor='alert_flag_reason'
                      className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                    >
                      UNDERDRAW Reason :
                    </label>
                    <div className='col-sm-6'>
                      <select
                        className='form-select form-select-sm'
                        id='alert_flag_reason'
                        name='alert_flag_reason'
                        onChange={handleChange}
                        value={
                          formData.alert_flag_reason
                            ? formData.alert_flag_reason
                            : ''
                        }
                        disabled={formDisable}
                      >
                        <option selected value='' disabled>
                          None
                        </option>
                        <option value={'No Plasma'}>No Plasma</option>
                        <option value={'Machine Failure'}>
                          Machine Failure
                        </option>
                        <option value={'Operator Error'}>Operator Error</option>
                        <option value={'Donor Asked to Disconnect'}>
                          Donor Asked to Disconnect
                        </option>
                        <option value={'Refused a Restick'}>
                          Refused a Restick
                        </option>
                        <option value={'Clogged Filter'}>Clogged Filter</option>
                        <option value={'Restick did not work'}>
                          Restick Did not work
                        </option>
                        <option value={'Others'}>Others</option>
                      </select>
                      {errors.alert_flag_reason != undefined ? (
                        <span className={styles.validationError}>
                          {errors.alert_flag_reason}
                        </span>
                      ) : null}
                    </div>
                  </div>
                )}
                {formData.alert_flags == 'OVERDRAW' && (
                  <div className='form-group row'>
                    <label
                      htmlFor='alert_flag_reason'
                      className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                    >
                      OVERDRAW Reason :
                    </label>
                    <div className='col-sm-6'>
                      <select
                        className='form-select form-select-sm'
                        id='alert_flag_reason'
                        name='alert_flag_reason'
                        onChange={handleChange}
                        value={
                          formData.alert_flag_reason
                            ? formData.alert_flag_reason
                            : ''
                        }
                        disabled={formDisable}
                      >
                        <option selected value='' disabled>
                          None
                        </option>
                        <option value={'Equipment Malfunction'}>
                          Equipment Malfunction
                        </option>
                        <option value={'Nomogram programming error'}>
                          Nomogram programming error
                        </option>
                        <option value={'Weigher arm issues'}>
                          Weigher arm issues
                        </option>
                        <option value={'Touching bottle'}>
                          Touching bottle
                        </option>
                        <option value={'Others'}>Others</option>
                      </select>
                      {errors.alert_flag_reason != undefined ? (
                        <span className={styles.validationError}>
                          {errors.alert_flag_reason}
                        </span>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className='form-group row align-items-center'>
                  <label
                    htmlFor='comments'
                    className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                  >
                    Comments :
                  </label>
                  <div className='col-sm-6'>
                    <textarea
                      rows={6}
                      className='form-control form-control-sm'
                      id='comments'
                      placeholder='Please enter comments'
                      name='phlebotomy_comments'
                      value={formData.phlebotomy_comments}
                      onChange={handleChange}
                      disabled={formDisable}
                    />
                    {errors.phlebotomy_comments != undefined ? (
                      <span className={styles.validationError}>
                        {errors.phlebotomy_comments}
                      </span>
                    ) : null}
                  </div>
                </div>
              </fieldset>
              <div className='row'>
                <div className='col-sm-2'>
                  <button
                    className='btn btn-dark mt-4'
                    style={{
                      width: '80px',
                      height: '30px',
                      padding: '0px',
                      fontSize: '14px'
                    }}
                    disabled={
                      formDisable ||
                      (formData.plasma_amt_machine &&
                        formData.plasma_amt_machine > 0)
                    }
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
                <div className='col-sm-3'>
                  <button
                    className='btn btn-primary mt-4'
                    style={{
                      width: '150px',
                      height: '30px',
                      padding: '0px',
                      fontSize: '14px'
                    }}
                    type='submit'
                    disabled={formDisable}
                  >
                    Complete Donation
                  </button>
                </div>
                <div className='col-sm-3'>
                  <button
                    className='btn btn-danger mt-4'
                    style={{
                      width: '100px',
                      height: '30px',
                      padding: '0px',
                      fontSize: '14px'
                    }}
                    onClick={exitButtonHandler}
                  >
                    Exit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className='col-sm-3 offset-1'>
            <table
              className='table table-bordered mb-4'
              style={{ fontSize: '14px' }}
            >
              <thead>
                <tr>
                  <th>Donor Weight</th>
                  <th>Plasma Volume or Weight</th>
                  <th>Collection Volume</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>110-149 lbs</td>
                  <td>625 mL (640g)</td>
                  <td>690 mL (705g)</td>
                </tr>
                <tr>
                  <td>150-174 lbs</td>
                  <td>750 mL (770g)</td>
                  <td>825 mL (845g)</td>
                </tr>
                <tr>
                  <td>175 lbs & above</td>
                  <td>800 mL (820g)</td>
                  <td>880 mL (900g)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      {renderContent()}
      <Modal
        show={phlebotomyStatus && phlebotomyStatus.eligible === false}
        backdrop='static'
        keyboard={false}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <h5>
            <FontAwesomeIcon icon={faTriangleExclamation}></FontAwesomeIcon>{' '}
            Warning
          </h5>
        </Modal.Header>
        <Modal.Body>
          <p>{phlebotomyStatus.reason}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => history.push('/dashboard')}>
            Exit
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    roleid: state.roleid,
    displayName: state.displayName
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Phlebotomist)
