import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'

function ProtectedRoute ({ component: Component, ...restOfProps }) {
  if (restOfProps.authenticated) {
    const routes = {
      1: [
        '/advancedSearch',
        '/donor/register',
        '/dashboard',
        '/cdcsReport',
        '/donor/dis',
        '/scanUCN',
        '/donorReport',
        '/donorUpload',
        '/kpiReport',
        '/donorCenterQueue'
      ],
      2: [
        '/advancedSearch',
        '/dashboard',
        '/labOrder',
        '/cdcsReport',
        '/labOrderReport',
        '/donor/dis',
        '/scanUCN',
        '/donorReport',
        '/kpiReport'
      ],
      3: [
        '/advancedSearch',
        '/donor/register',
        '/dashboard',
        '/donations',
        '/cases',
        '/casePacking/:caseno',
        '/ucnLocationUpdate',
        '/labOrder',
        '/cdcsReport',
        '/labOrderReport',
        '/donor/dis',
        '/scanUCN',
        '/labResultsReport',
        '/donorReport',
        '/shippingSummaryReport',
        '/shippingDetailsReport',
        '/kpiReport',
        '/donorCenterQueue'
      ],
      4: ['/dashboard', '/admin/userManagement'],
      5: ['/cases', '/casePacking/:caseno', '/dashboard', '/kpiReport'],
      6: ['/dashboard', '/uploadLabResults'],
      7: [
        '/advancedSearch',
        '/dashboard',
        '/donations',
        '/labOrder',
        '/cdcsReport',
        '/labOrderReport',
        '/donor/dis',
        '/scanUCN',
        '/healthAssessments',
        '/labResultsReport',
        '/donorReport',
        '/kpiReport'
      ],
      8: [
        '/advancedSearch',
        '/dashboard',
        '/donor/dis',
        '/scanUCN',
        '/labResultsReport',
        '/healthAssessments',
        '/donorReport',
        '/kpiReport'
      ]
    }
    if (restOfProps.roleid) {
      if (routes[restOfProps.roleid].includes(restOfProps.path)) {
        return (
          <Route {...restOfProps} render={props => <Component {...props} />} />
        )
      } else {
        return (
          <Route {...restOfProps} render={props => <Redirect to='/login' />} />
        )
      }
    } else {
      return null
    }
  } else {
    return <Route {...restOfProps} render={props => <Redirect to='/login' />} />
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}
const mapStateToProps = state => {
  return {
    roleid: state.roleid
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
