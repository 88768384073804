import React, { useCallback, useEffect, useState } from 'react'
import { Card, Select, Input, Button, Table, Tooltip } from 'antd'

import { request } from '../utilities/msalFetch'
import moment from 'moment'
import { toast } from 'react-toastify'
import { formatDate } from '../utilities/format'
import { Modal } from 'react-bootstrap'

const { TextArea } = Input

const alertColumns = [
  {
    title: 'Donor Id',
    dataIndex: 'donor_id',
    key: 'donor_id'
  },
  {
    title: 'Category',
    dataIndex: 'alert_category',
    key: 'alert_category'
  },
  {
    title: 'Comment',
    dataIndex: 'alert_comments',
    key: 'alert_comments',
    render: c => (
      <Tooltip placement='topLeft' title={c}>
        <p className='text-truncate alertCommentWrap'>{c}</p>
      </Tooltip>
    )
  },
  {
    title: 'Date',
    dataIndex: 'alert_date',
    key: 'alert_date',
    render: d => {
      return <span>{moment(d).isValid() ? formatDate(d) : ''}</span>
    }
  },
  {
    title: 'Raised By',
    dataIndex: 'raised_by',
    key: 'raised_by'
  },
  {
    title: 'Closed Date',
    dataIndex: 'resolve_date',
    key: 'resolve_date',
    render: d => {
      return <span>{moment(d).isValid() ? formatDate(d) : ''}</span>
    }
  },
  {
    title: 'Resolved Comments',
    dataIndex: 'resolved_comments',
    key: 'resolved_comments',
    render: c => (
      <Tooltip placement='topLeft' title={c}>
        <p className='text-truncate alertCommentWrap'>{c}</p>
      </Tooltip>
    )
  },
  {
    title: 'Closed By',
    dataIndex: 'resolved_by',
    key: 'resolved_by'
  }
]

const initialFormData = {
  alertCategory: '',
  alertComments: ''
}

function DonorAlerts ({ donorId, setAlertCount, token }) {
  const [formData, setFormData] = useState(initialFormData)
  const [submiting, setSubmitting] = useState(false)
  const [allAlerts, setAllAlerts] = useState([])
  const [alert, setAlert] = useState(null)
  const [closingAlert, setClosingAlert] = useState(null)

  const fetchAllAlerts = useCallback(async () => {
    const res = await request.get(`/api/donor/allAlerts/${donorId}`)
    if (res.status === 200) {
      setAllAlerts(res.data)
    }
  }, [donorId])

  useEffect(() => {
    fetchAllAlerts()
  }, [fetchAllAlerts])

  const handleSubmit = async () => {
    setSubmitting(true)
    const res = await request.post(`/api/donor/addAlert`, {
      donor_id: donorId,
      ...formData
    })
    if (res.data.success) {
      toast.success(res.data.message)
      fetchAllAlerts()
      setAlertCount(prev => prev + 1)
      setFormData(initialFormData)
    }
    setSubmitting(false)
  }

  const openAlertResolveModal = data => {
    setAlert(data)
  }

  const resolveAlert = async (id, resolveComment) => {
    setClosingAlert(true)
    try {
      await request.put(`/api/donor/closeAlert/${id}`, {
        resolveComment: resolveComment
      })
      fetchAllAlerts()
      setAlertCount(prev => prev - 1)
      toast.success('Alert Closed')
      setAlert(null)
    } catch (e) {
      toast.error('Failed to close alert')
    } finally {
      setClosingAlert(false)
    }
  }

  const columns = [
    ...alertColumns,
    {
      title: 'Action',
      dataIndex: 'alertId',
      key: 'alertId',
      render: (id, data) => (
        <Button
          size='small'
          type='primary'
          disabled={data?.resolved_by}
          onClick={() => openAlertResolveModal(data)}
        >
          Close
        </Button>
      )
    }
  ]

  const isFormValid = formData.alertCategory && formData.alertComments

  return (
    <div className='row justify-content-center'>
      <div className='col-md-12 p-4'>
        <h3 className='title'>Donor Alerts Management</h3>
        <div className='row '>
          <div className='col-md-3'>
            <Card title='Create Alert' size='small'>
              <>
                <div className='form-group my-2'>
                  <label
                    htmlFor='alertCategory'
                    className='col-sm-5 col-form-label'
                  >
                    Alert Category:
                  </label>
                  <div>
                    <Select
                      className='w-100'
                      placeholder='Select'
                      value={formData.alertCategory}
                      onChange={value =>
                        setFormData({ ...formData, alertCategory: value })
                      }
                    >
                      <Select.Option value='General'>General</Select.Option>
                      <Select.Option value='SPE Required'>
                        SPE Required
                      </Select.Option>
                      <Select.Option value='Counselling Required'>
                        Counselling Required
                      </Select.Option>
                      <Select.Option value='Health Assessment Required'>
                        Health Assessment Required
                      </Select.Option>
                    </Select>
                  </div>
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='alertComments'
                    className='col-sm-5 col-form-label'
                  >
                    Alert Comments:
                  </label>
                  <div>
                    <TextArea
                      rows={3}
                      value={formData.alertComments}
                      placeholder='Alert Comments'
                      maxLength={512}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          alertComments: e.target.value
                        })
                      }
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='mt-3'>
                    <Button
                      type='primary'
                      onClick={handleSubmit}
                      disabled={!isFormValid}
                      loading={submiting}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </>
            </Card>
          </div>
          <div className='col-md-9'>
            <Card title='All Alerts' size='small'>
              <Table
                dataSource={allAlerts}
                columns={columns}
                pagination={false}
                size={'small'}
                rowKey={'alertId'}
              />
            </Card>
          </div>
        </div>
      </div>
      {!!alert && (
        <ResolveAlertModal
          alert={alert}
          onClose={() => {
            setAlert(null)
          }}
          resolveAlert={resolveAlert}
          closingAlert={closingAlert}
        />
      )}
    </div>
  )
}

export default DonorAlerts

const ResolveAlertModal = ({ alert, onClose, resolveAlert, closingAlert }) => {
  const [resolveComment, setResolveCommment] = useState('')

  return (
    <Modal show={!!alert} onHide={onClose} centered backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>
          <strong> {alert?.alert_category}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='text-break'>{alert?.alert_comments}</div>
        <div className='form-group'>
          <label
            htmlFor='resolveComments '
            className='col-form-label font-weight-bold'
          >
            Resolve Comments:
          </label>

          <TextArea
            rows={3}
            value={resolveComment}
            placeholder='Resolve Comments'
            maxLength={512}
            id='resolveComments'
            onChange={e => setResolveCommment(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type='primary'
          onClick={() => resolveAlert(alert?.alertId, resolveComment)}
          disabled={!resolveComment.trim()}
          loading={closingAlert}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
