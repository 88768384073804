import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import styles from '../styles/DIS.module.css'
import DonorUpdate from './DonorUpdate'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { toast } from 'react-toastify'
import { formatDonorId, formatPhoneNumber } from '../utilities/format'
import { formatDate } from '../utilities/format'
import { Comment } from 'semantic-ui-react'
import { Timeline } from 'antd'
import moment from 'moment'
import { msalFetch } from '../utilities/msalFetch'
import NoImage from '../images/no_image.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faLockOpen, faLock } from '@fortawesome/free-solid-svg-icons'
import { InputMask } from 'primereact/inputmask'

const DI = props => {
  const [edit, setEdit] = useState(false)
  const [showUpdateStatusModal, setUpdateStatusModal] = useState(false)
  const [donorType, setDonorType] = useState('')
  const [showUpdateTypeModal, setUpdateTypeModal] = useState(false)
  const [showDeferralInput, setShowDeferralInput] = useState(false)
  const [statusUpdateFormErrors, setStatusUpdateFormErrors] = useState({})
  const [comment, setComment] = useState('')
  const [comments, setComments] = useState([])
  const [typeUpdateComments, setTypeUpdateComments] = useState('')
  const [statusLogs, setStatusLogs] = useState('')
  const [lastTDucnlist, setLastTDucnlist] = useState([])
  const [statusUpdateData, setStatusUpdateData] = useState({})
  const [donorInfoLogs, setDonorInfoLogs] = useState([])
  const [toggleSSNupate, setToggleSSNupdate] = useState(false)
  const [newSSN, setNewSSN] = useState(null)
  const [unmaskedSSN, setUnmaskedSSN] = useState(null)

  const getLogs = () => {
    const body = {
      donor_id: props.donorInfo.donor_id
    }
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(body)
    }
    msalFetch('/api/donor/getDonorLogs', options).then(async res => {
      if (res.ok) {
        const data = await res.json()
        const comments = []
        const donorInfoLogs = []
        const donorStatusLogs = []
        data.forEach(x => {
          if (
            x.change_type === 'DONOR_STATUS' ||
            x.change_type === 'DONOR_TYPE'
          ) {
            let a = {
              label: (
                <div className=''>
                  <p style={{ fontSize: '12px', margin: '0px', padding: '0' }}>
                    {x.new_value}
                  </p>
                  <p style={{ fontSize: '12px', margin: '0px', padding: '0' }}>
                    {x.user}, <span>{formatDate(x.datetime)}</span>
                  </p>
                </div>
              ),
              children: (
                <span className=''>
                  {x.change_info ? x.change_info : 'No comments'}{' '}
                </span>
              )
            }
            donorStatusLogs.push(a)
          }
          if (x.change_type === 'COMMENT') {
            comments.push(x)
          }
          if (x.change_type === 'DONOR_INFO') {
            donorInfoLogs.push(x)
          }
        })
        setComments(comments)
        setStatusLogs(donorStatusLogs.reverse())
        setDonorInfoLogs(donorInfoLogs.reverse())
      } else {
        toast.error('Failed to get comments')
        setComments([])
      }
    })
  }

  useEffect(() => {
    //props.getDonorInfo()
  }, [])

  useEffect(() => {
    if (props.donorInfo.donor_id) {
      getLogs()
    }
  }, [props.donorInfo])

  const statusChange = e => {
    setStatusUpdateData({
      ...statusUpdateData,
      [e.target.name]: e.target.value
    })
    if (e.target.value == 'TEMPORARILY DEFERRED') {
      setShowDeferralInput(true)
    } else {
      setShowDeferralInput(false)
    }
  }

  const handleDeferralDateChange = e => {
    let date = e.target.value
    date = moment(date)
    const today = new Date().toISOString().slice(0, 10)
    const diffDays = date.diff(moment(today), 'day')

    if (diffDays <= 0) {
      setStatusUpdateFormErrors({
        dateError: 'Date cannot be less than today'
      })
    } else {
      setStatusUpdateData({
        ...statusUpdateData,
        [e.target.name]: e.target.value
      })
      setStatusUpdateFormErrors({})
    }
  }

  const handleStatusUpdateButtonDisable = () => {
    console.log(statusUpdateData)
    if (!statusUpdateData.newStatus || !statusUpdateData.comments) {
      return true
    } else if (
      statusUpdateData.newStatus === 'TEMPORARILY DEFERRED' &&
      !statusUpdateData.deferralTill
    ) {
      return true
    } else if (
      props.donorInfo.donor_status === 'TEMPORARILY DEFERRED (Abnormal SPE)' &&
      props.roleid == 8 &&
      !statusUpdateData.ucn
    ) {
      return true
    } else return false
  }

  const getLastTemporarilyDeferredUCN = () => {
    const body = {
      donor_id: props.donorInfo.donor_id,
      type: 'TEMPORARILY DEFERRED (Abnormal SPE)'
    }
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(body)
    }
    msalFetch('/api/donor/getLastDeferredUCN', options).then(async res => {
      if (res.ok) {
        const data = await res.json()
        setLastTDucnlist(data)
      } else {
        setLastTDucnlist([])
      }
    })
  }

  const statusChangeSubmit = () => {
    props.actions.setLoading()
    const body = {
      donorId: props.donorInfo.donor_id,
      newStatus: statusUpdateData.newStatus,
      deferralTill: statusUpdateData.deferralTill,
      comments: statusUpdateData.comments,
      ucn: statusUpdateData.ucn,
      currentStatus: props.donorInfo.donor_status
    }
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(body)
    }
    msalFetch('/api/donor/changeDonorStatus', options).then(async res => {
      if (res.ok) {
        toast.success('Updated status successfully')
        setUpdateStatusModal(false)
        props.getDonorInfo()
        setStatusUpdateData({})
      } else {
        setUpdateStatusModal(false)
        toast.error('Failed to update')
        props.actions.clearLoading()
        setStatusUpdateData({})
      }
    })
  }

  const typeChangeSubmit = () => {
    props.actions.setLoading()
    const body = {
      donorId: props.donorInfo.donor_id,
      donorType,
      comments: typeUpdateComments
    }
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(body)
    }
    msalFetch('/api/donor/changeDonorType', options).then(async res => {
      if (res.ok) {
        toast.success('Updated donor type successfully')
        setUpdateTypeModal(false)
        setTypeUpdateComments('')
        props.getDonorInfo()
      } else {
      }
    })
  }

  const handleCommentsChange = e => {
    setComment(e.target.value)
  }

  const handleCommentSubmit = e => {
    if (comment != '') {
      const body = {
        donor_id: props.donorInfo.donor_id,
        change_type: 'COMMENT',
        change_info: comment
      }
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(body)
      }
      msalFetch('/api/donor/addComment', options)
        .then(async res => {
          if (res.ok) {
            toast.success('Comment added successfully')
            setComment('')
          } else {
            toast.error('Comment could not be added')
          }
        })
        .catch(err => {
          toast.error('Comment could not be added')
        })
    }
  }

  const getStatusBadge = status => {
    switch (status) {
      case 'ACTIVE':
        return 'bg-success'
      case 'TEMPORARILY DEFERRED':
        return 'bg-danger'
      case 'PERMANENTLY DEFERRED':
        return 'bg-danger'
      case 'TEMPORARILY DEFERRED (Abnormal SPE)':
        return 'bg-danger'
      case 'APPLICANT 1':
        return 'bg-warning'
      case 'APPLICANT 2':
        return 'bg-warning'
      case 'QUALIFIED':
        return 'bg-success'
      case 'NEW':
        return 'bg-secondary'
      default:
        return null
    }
  }

  const renderHeight = height => {
    if (height) {
      const a = height.toString().split('.')
      if (a.length > 1) {
        return `${a[0]}' ${a[1]}"`
      } else {
        return `${a[0]} feet`
      }
    }
    return null
  }

  const renderDonorStatusUpdateButton = () => {
    if (
      (props.roleid == 3 || props.roleid == 7) &&
      props.donorInfo &&
      props.donorInfo.donor_status !== 'PERMANENTLY DEFERRED' &&
      props.donorInfo.donor_status !== 'TEMPORARILY DEFERRED (Abnormal SPE)'
    ) {
      return (
        <button
          className='btn btn-sm btn-primary'
          style={{
            padding: '0 20px',
            height: '35px',
            marginRight: '20px'
          }}
          onClick={() => setUpdateStatusModal(true)}
        >
          Update Donor Status
        </button>
      )
    } else if (props.roleid == 8) {
      return (
        <button
          className='btn btn-sm btn-primary'
          style={{
            padding: '0 20px',
            height: '35px',
            marginRight: '20px'
          }}
          onClick={() => {
            setUpdateStatusModal(true)
            getLastTemporarilyDeferredUCN()
          }}
        >
          Update Donor Status
        </button>
      )
    }
  }

  const submitSSNUpdate = () => {
    if (newSSN) {
      const body = {
        donor_id: props.donorInfo.donor_id,
        newSSN
      }
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(body)
      }
      props.actions.setLoading()
      msalFetch('/api/donor/updateSSN', options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            toast.success('SSN updated successfully')
            setToggleSSNupdate(false)
            props.getDonorInfo()
          } else {
            const data = await res.json()
            toast.error(data.message)
          }
        })
        .catch(err => {
          props.actions.clearLoading()
          toast.error(err.message)
        })
    } else {
      toast.error('Please enter new valid SSN')
    }
  }

  const getUnMaskedSSN = () => {
    if (!unmaskedSSN) {
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        }
      }
      msalFetch(
        `/api/donor/unmaskSSN/${props.donorInfo.donor_id}`,
        options
      ).then(async res => {
        if (res.ok) {
          const data = await res.json()
          setUnmaskedSSN(data.unmaskedSSN)
        } else {
          toast.error('Failed to get Unmasked SSN')
        }
      })
    } else {
      setUnmaskedSSN(null)
    }
  }

  if (props.donorInfo.donor_id != undefined) {
    if (edit) {
      return (
        <DonorUpdate
          donorInfo={props.donorInfo}
          image={props.image}
          b64={props.b64}
          setEdit={setEdit}
          getDonorInfo={props.getDonorInfo}
        ></DonorUpdate>
      )
    } else {
      return (
        props.donorInfo.donor_id && (
          <React.Fragment>
            <div className={styles.mainBody}>
              <div className='row gutters-sm'>
                <div className='col-md-4 mb-2'>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='d-flex flex-column align-items-center text-center'>
                        <img
                          src={props.image ? props.image : NoImage}
                          alt='picture'
                          className='rounded'
                          width='150'
                        />
                        <div className='mt-2'>
                          <h4>
                            {props.donorInfo.first_name}{' '}
                            {props.donorInfo.last_name}
                          </h4>
                          <p className='text-secondary mb-1'>
                            SSN :{' '}
                            {toggleSSNupate ? (
                              <>
                                <InputMask
                                  id='ssn'
                                  mask='999-99-9999'
                                  placeholder='999-99-9999'
                                  style={{ width: '120px' }}
                                  onChange={e => setNewSSN(e.target.value)}
                                  value={newSSN}
                                ></InputMask>
                                <Button
                                  size='sm'
                                  className='mx-2'
                                  onClick={submitSSNUpdate}
                                >
                                  Update
                                </Button>
                              </>
                            ) : (
                              <>
                                <span
                                  style={{ fontWeight: '600' }}
                                  className='mx-1'
                                >
                                  {unmaskedSSN
                                    ? unmaskedSSN
                                    : formatDonorId(props.donorInfo.ssn)}
                                </span>
                                {props.roleid === 3 ? (
                                  <button className='btn p-1'>
                                    <FontAwesomeIcon
                                      icon={unmaskedSSN ? faLock : faLockOpen}
                                      onClick={getUnMaskedSSN}
                                    ></FontAwesomeIcon>
                                  </button>
                                ) : null}
                                {props.roleid === 3 ? (
                                  <button
                                    onClick={() => setToggleSSNupdate(true)}
                                    className='btn p-1 mx-1'
                                  >
                                    <FontAwesomeIcon
                                      icon={faPencil}
                                      color='2E236C'
                                    ></FontAwesomeIcon>
                                  </button>
                                ) : null}
                              </>
                            )}
                          </p>
                          <p className='text-secondary mb-1'>
                            Donor ID:{' '}
                            <span style={{ fontWeight: '600' }}>
                              {props.donorInfo.donor_id}
                            </span>
                          </p>
                          <p className='text-muted font-size-sm'>{}</p>
                          {renderDonorStatusUpdateButton()}
                          {props.roleid == 8 ||
                          props.roleid == 3 ||
                          props.roleid == 7 ? (
                            <button
                              className='btn btn-sm btn-secondary'
                              style={{
                                padding: '0 20px',
                                height: '35px',
                                marginRight: '20px'
                              }}
                              onClick={() => setUpdateTypeModal(true)}
                            >
                              Update Donor Type
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {props.donorInfo?.allergic_to ? (
                    <div className='card mt-2 text-white'>
                      <ul className='list-group list-group-flush'>
                        <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap bg-danger text-white'>
                          <h6 className='mb-0'>Allergic To</h6>
                          <span className='text-white'>
                            <span style={{ fontWeight: '600' }}>
                              {props.donorInfo.allergic_to}
                            </span>
                          </span>
                        </li>
                        {props.donorInfo.allergy_comments ? (
                          <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap bg-danger text-white'>
                            <h6 className='mb-0'>Comments</h6>
                            <span className='text-white'>
                              <span>{props.donorInfo.allergy_comments}</span>
                            </span>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  ) : null}
                  <div className='card mt-2'>
                    <ul className='list-group list-group-flush'>
                      <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap'>
                        <h6 className='mb-0'>NDDR Trans Id</h6>
                        <span className='text-secondary'>
                          <span style={{ fontWeight: '600' }}>
                            {props.donorInfo.nddr}
                          </span>
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap'>
                        <h6 className='mb-0'>NDDR Check</h6>
                        <span className='text-secondary'>
                          <span
                            className={
                              props.donorInfo?.nddr_check == 'Fail'
                                ? 'text-danger'
                                : 'text-success'
                            }
                            style={{ fontWeight: '600' }}
                          >
                            {props.donorInfo.nddr_check}
                          </span>
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap'>
                        <h6 className='mb-0'>NDDR Trans Date</h6>
                        <span className='text-secondary'>
                          <span style={{ fontWeight: '600' }}>
                            {props.donorInfo?.nddr_date
                              ? formatDate(props.donorInfo.nddr_date)
                              : ''}
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className='card mt-2'>
                    <ul className='list-group list-group-flush'>
                      <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap'>
                        <h6 className='mb-0'>Donor Status</h6>
                        <span className='text-secondary'>
                          <span
                            className={`badge ${getStatusBadge(
                              props.donorInfo.donor_status
                            )}`}
                            style={{ fontSize: '14px' }}
                          >
                            {props.donorInfo.donor_status}
                          </span>
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap'>
                        <h6 className='mb-0'>Donor Type</h6>
                        <span className='text-secondary'>
                          <span
                            className={`badge ${getStatusBadge(
                              props.donorInfo.donor_type
                            )}`}
                            style={{ fontSize: '14px' }}
                          >
                            {props.donorInfo.donor_type}
                          </span>
                        </span>
                      </li>

                      <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap '>
                        <h6 className='mb-0'>Temporarily Deferred Until</h6>

                        <span
                          className='badge bg-danger'
                          style={{ fontSize: '16px' }}
                        >
                          {props.donorInfo?.deferral_till &&
                          moment(props.donorInfo?.deferral_till).isValid()
                            ? formatDate(props.donorInfo.deferral_till)
                            : ''}
                        </span>
                      </li>

                      <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap'>
                        <h6 className='mb-0'>Last SPE Sample Date</h6>
                        <span className='text-secondary'>
                          {props.donorInfo.spe_sample_date &&
                          moment(props.donorInfo.spe_sample_date).isValid()
                            ? formatDate(props.donorInfo.spe_sample_date)
                            : ''}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap'>
                        <h6 className='mb-0'>Last Full Physical Date</h6>
                        <span className='text-secondary'>
                          {props.donorInfo.last_full_physical_date &&
                          moment(
                            props.donorInfo.last_full_physical_date
                          ).isValid()
                            ? formatDate(
                                props.donorInfo.last_full_physical_date
                              )
                            : ''}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap'>
                        <h6 className='mb-0'>Vein Grade (Left Arm)</h6>
                        <span className='text-secondary'>
                          {props.donorInfo.vein_grade_left}
                        </span>
                      </li>
                      <li className='list-group-item d-flex justify-content-between align-items-center flex-wrap'>
                        <h6 className='mb-0'>Vein Grade (Right Arm)</h6>
                        <span className='text-secondary'>
                          {props.donorInfo.vein_grade_right}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className='card mt-2'>
                    <div className={styles.commentsMain}>
                      <div className={styles.addCommentSection}>
                        <input
                          type='text'
                          className={styles.commentsInput}
                          placeholder='Type something here'
                          onChange={handleCommentsChange}
                          value={comment}
                        ></input>
                        <button
                          className='btn btn-primary'
                          onClick={handleCommentSubmit}
                        >
                          Add
                        </button>
                      </div>
                      <div className={styles.commentsSection}>
                        {comments.length
                          ? comments.map((x, i) => {
                              return (
                                <Comment.Group>
                                  <Comment>
                                    <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/jenny.jpg' />
                                    <Comment.Content>
                                      <Comment.Author as='a'>
                                        {x.user}
                                      </Comment.Author>
                                      <Comment.Metadata>
                                        <div>{formatDate(x.datetime)}</div>
                                      </Comment.Metadata>
                                      <Comment.Text>
                                        {x.change_info}
                                      </Comment.Text>
                                    </Comment.Content>
                                  </Comment>
                                </Comment.Group>
                              )
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-8'>
                  <div className='card mb-2'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>First Name</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {props.donorInfo.first_name}
                        </div>
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Middle Name</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {props.donorInfo.middle_name}
                        </div>
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Last Name</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {props.donorInfo.last_name}
                        </div>
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Date of Birth</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {formatDate(props.donorInfo.dob)}
                        </div>
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Gender</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {props.donorInfo.gender == 'M' ? 'Male' : 'Female'}
                        </div>
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Height</h6>
                        </div>
                        {props.donorInfo?.height && (
                          <div className='col-sm-9 text-secondary'>
                            {renderHeight(props.donorInfo.height)}
                          </div>
                        )}
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Phone</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {formatPhoneNumber(props.donorInfo.phone)}
                        </div>
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Emergency Contact</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {props.donorInfo.emergency_contact_name}
                        </div>
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Emergency Contact Number</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {formatPhoneNumber(
                            props.donorInfo.emergency_contact_number
                          )}
                        </div>
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Full Address</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {props.donorInfo.addr1 +
                            ', ' +
                            props.donorInfo.city +
                            ', ' +
                            props.donorInfo.state +
                            ', ' +
                            props.donorInfo.country +
                            ', ' +
                            props.donorInfo.zipcode}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Address Verified</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {props.donorInfo.address_verified}
                        </div>
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <button
                            className='btn btn-secondary'
                            style={{
                              width: '100px',
                              height: '35px',
                              marginRight: '20px'
                            }}
                            onClick={() => setEdit(!edit)}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='card mb-2'
                    style={{ height: '32.5%', overflow: 'auto' }}
                  >
                    <div
                      className='card-body'
                      style={{ fontSize: '14px', fontWeight: 600 }}
                    >
                      <p className={styles.timeLineTitle}>
                        Donor Status Timeline
                      </p>
                      <Timeline
                        mode='left'
                        items={statusLogs}
                        style={{ width: '50%' }}
                        className='mt-4'
                      ></Timeline>
                    </div>
                  </div>
                  <div className='card mt-2'>
                    <div className='card-body'>
                      <p className={styles.timeLineTitle}>Change Logs</p>
                      <table className='table table-sm table-bordered'>
                        <thead>
                          <tr>
                            <th>User</th>
                            <th>Field</th>
                            <th>Old Value</th>
                            <th>New Value</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {donorInfoLogs.map(x => {
                            const metadata = JSON.parse(x.meta_data)
                            return metadata ? (
                              metadata.map(y => {
                                return (
                                  <tr>
                                    <td>{x.user}</td>
                                    <td>{y.key}</td>
                                    <td>
                                      {y.oldValue?.toString().length >= 10 &&
                                      moment(y.oldValue).isValid()
                                        ? formatDate(y.oldValue)
                                        : y.oldValue}
                                    </td>
                                    <td>
                                      {y.newValue?.toString().length >= 10 &&
                                      moment(y.newValue).isValid()
                                        ? formatDate(y.newValue)
                                        : y.newValue}
                                    </td>
                                    <td>{formatDate(x.datetime)}</td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>{x.user}</td>
                                <td>{x.sub_type}</td>
                                <td>{x.old_value}</td>
                                <td>{x.new_value}</td>
                                <td>{formatDate(x.datetime)}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showUpdateStatusModal ? (
              <Modal
                show={showUpdateStatusModal}
                backdrop='static'
                keyboard={false}
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                centered
              >
                <Modal.Header>
                  <Modal.Title>Donor Status Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='form-group row'>
                    <label htmlFor='status' className='col-sm-3 col-form-label'>
                      Status<span style={{ color: 'red' }}>&#42;</span> :
                    </label>
                    <div className='col-sm-9'>
                      <select
                        className='form-select'
                        id='status'
                        onChange={statusChange}
                        name='newStatus'
                      >
                        <option disabled selected>
                          Select
                        </option>
                        {props.roleid !== 1 ? (
                          <option
                            value='ACTIVE'
                            disabled={props.donorInfo.donor_status === 'ACTIVE'}
                          >
                            ACTIVE
                          </option>
                        ) : null}
                        <option
                          value='TEMPORARILY DEFERRED'
                          disabled={
                            props.donorInfo.donor_status ===
                            'TEMPORARILY DEFERRED'
                          }
                        >
                          TEMPORARILY DEFERRED
                        </option>
                        {props.roleid !== 1 ? (
                          <option
                            value='TEMPORARILY DEFERRED (Abnormal SPE)'
                            disabled={
                              props.donorInfo.donor_status ===
                              'TEMPORARILY DEFERRED (Abnormal SPE)'
                            }
                          >
                            TEMPORARILY DEFERRED (Abnormal SPE)
                          </option>
                        ) : null}
                        {props.roleid !== 1 ? (
                          <option
                            value='PERMANENTLY DEFERRED'
                            disabled={
                              props.donorInfo.donor_status ===
                              'PERMANENTLY DEFERRED'
                            }
                          >
                            PERMANENTLY DEFERRED
                          </option>
                        ) : null}
                      </select>
                    </div>
                  </div>
                  {showDeferralInput ? (
                    <div className='form-group row mt-4'>
                      <label
                        htmlFor='deferalldate'
                        className='col-sm-3 col-form-label'
                      >
                        Deferall Till<span style={{ color: 'red' }}>&#42;</span>{' '}
                        :
                      </label>
                      <div className='col-sm-9'>
                        <input
                          type='date'
                          className='form-control'
                          id='deferalldate'
                          name='deferralTill'
                          onChange={handleDeferralDateChange}
                        ></input>
                        {statusUpdateFormErrors.dateError && (
                          <p style={{ fontSize: '12px', color: 'red' }}>
                            {statusUpdateFormErrors.dateError}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className='form-group row mt-4'>
                    <label
                      htmlFor='comments'
                      className='col-sm-3 col-form-label'
                    >
                      Comments<span style={{ color: 'red' }}>&#42;</span> :
                    </label>
                    <div className='col-sm-9'>
                      <textarea
                        className='form-control'
                        name='comments'
                        onChange={e =>
                          setStatusUpdateData({
                            ...statusUpdateData,
                            [e.target.name]: e.target.value
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                  {props.donorInfo.donor_status ===
                  'TEMPORARILY DEFERRED (Abnormal SPE)' ? (
                    <div className='form-group row mt-4'>
                      <label htmlFor='ucn' className='col-sm-3 col-form-label'>
                        UCN<span style={{ color: 'red' }}>&#42;</span> :
                      </label>
                      <div className='col-sm-9'>
                        <select
                          className='form-select'
                          id='ucn'
                          name='ucn'
                          onChange={e =>
                            setStatusUpdateData({
                              ...statusUpdateData,
                              [e.target.name]: e.target.value
                            })
                          }
                        >
                          <option disabled selected>
                            Select
                          </option>
                          {lastTDucnlist.length &&
                            lastTDucnlist.map(x => (
                              <option value={x.ucn}>{x.ucn}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  ) : null}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant='primary'
                    size='sm'
                    style={{ width: '80px' }}
                    disabled={handleStatusUpdateButtonDisable()}
                    onClick={statusChangeSubmit}
                  >
                    Submit
                  </Button>
                  <Button
                    variant='danger'
                    size='sm'
                    style={{ width: '80px' }}
                    onClick={() => {
                      setUpdateStatusModal(false)
                      setStatusUpdateData({})
                      setShowDeferralInput(false)
                    }}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            ) : null}
            <Modal
              show={showUpdateTypeModal}
              backdrop='static'
              keyboard={false}
              size='md'
              aria-labelledby='contained-modal-title-vcenter'
              centered
              onHide={() => setUpdateTypeModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Donor Type Update</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='form-group row'>
                  <label
                    htmlFor='typeselect'
                    className='col-sm-3 col-form-label'
                  >
                    Donor Type<span style={{ color: 'red' }}>&#42;</span> :
                  </label>
                  <div className='col-sm-9'>
                    <select
                      className='form-select'
                      id='typeselect'
                      onChange={e => setDonorType(e.target.value)}
                    >
                      <option disabled selected>
                        Select
                      </option>
                      <option value='APPLICANT 1'>APPLICANT 1</option>
                      <option value='APPLICANT 2'>APPLICANT 2</option>
                      <option value='QUALIFIED'>QUALIFIED</option>
                    </select>
                  </div>
                </div>
                <div className='form-group row mt-4'>
                  <label htmlFor='comments' className='col-sm-3 col-form-label'>
                    Comments<span style={{ color: 'red' }}>&#42;</span> :
                  </label>
                  <div className='col-sm-9'>
                    <textarea
                      className='form-control'
                      onChange={e => setTypeUpdateComments(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='primary'
                  size='sm'
                  style={{ width: '80px' }}
                  disabled={donorType == '' && typeUpdateComments == ''}
                  onClick={typeChangeSubmit}
                >
                  Submit
                </Button>
                <Button
                  variant='danger'
                  size='sm'
                  style={{ width: '80px' }}
                  onClick={() => setUpdateTypeModal(false)}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </React.Fragment>
        )
      )
    }
  } else {
    return 'Loading...'
  }
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DI)
