import React, { useState, useRef, useMemo, useEffect } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { Input, DatePicker, Space, Button, Modal } from 'antd'
import ReactToPrint from 'react-to-print'
import PrintLabOrder from './PrintLabOrder'
import { msalFetch } from '../utilities/msalFetch'
import { formatDate } from '../utilities/format'
import { Table, Tag } from 'antd'
import {
  DownloadOutlined, CheckCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons'
import { checkValidUCN } from '../utilities/validations'

const columns = [
  {
    title: 'UCN',
    dataIndex: 'UCN',
    key: 'UCN'
  },
  {
    title: 'Donation Date',
    dataIndex: 'DONATION_DATE',
    key: 'DONATION_DATE'
  },
  {
    title: 'Donor Type',
    dataIndex: 'DONOR_TYPE',
    key: 'DONOR_TYPE'
  },
  {
    title: 'NAT_VMT',
    dataIndex: 'NAT_VMT',
    key: 'NAT_VMT'
  },
  {
    title: 'ATYA',
    dataIndex: 'ATYA',
    key: 'ATYA'
  },
  {
    title: 'SPE_RPR',
    dataIndex: 'SPE_RPR',
    key: 'SPE_RPR'
  },
  {
    title: 'Plasma Processor',
    dataIndex: 'PLASMA_PROCESSOR',
    key: 'PLASMA_PROCESSOR'
  },
  {
    title: 'Lab Result',
    dataIndex: 'Lab_Result',
    key: 'Lab_Result',
    render: (_, { Lab_Result }) => {
      switch (Lab_Result) {
        case 'Partial':
          return <Tag color='geekblue'>{Lab_Result}</Tag>
        case 'Pass':
          return <Tag color='green'>{Lab_Result}</Tag>
        case 'Reactive':
          return <Tag color='red'>{Lab_Result}</Tag>
        case 'Abnormal SPE':
          return <Tag color='yellow'>{Lab_Result}</Tag>
        case 'RPR Positive':
          return <Tag color='yellow'>{Lab_Result}</Tag>
        case 'ATYA Positive':
          return <Tag color='red'>{Lab_Result}</Tag>
        case 'No Records':
          return <p>{Lab_Result}</p>
      }
    },
    filters: [
      {
        text: 'Partial',
        value: 'Partal'
      },
      {
        text: 'Pass',
        value: 'Pass'
      },
      {
        text: 'Reactive',
        value: 'Reactive'
      },
      {
        text: 'Abnormal SPE',
        value: 'Abnormal SPE'
      },
      {
        text: 'ATYA Positive',
        value: 'ATYA Positive'
      },
      {
        text: 'RPR Positive',
        value: 'RPR Positive'
      },
      {
        text: 'No Records',
        value: 'No Records'
      },
    ],
    onFilter: (value, record) => record.Lab_Result.indexOf(value) === 0
  },
  {
    title: 'Plasma Sample Verified',
    dataIndex: 'plasma_sample_verified',
    key: 'plasma_sample_verified',
    render: (value) => {
      return <span>{value !== null ? value ? 'Y' : 'N' : ''}</span>
    },
    filters: [
      {
        text: 'Yes',
        value: 1
      },
      {
        text: 'No',
        value: 0
      },
      {
        text: 'Blank',
        value: null
      },

    ],
    onFilter: (value, record) => record.PLASMA_SAMPLE_TAKEN === value
  },
  {
    title: 'Serum Sample Verified',
    dataIndex: 'serum_sample_verified',
    key: 'serum_sample_verified',
    render: (value) => {
      return <span>{value !== null ? value ? 'Y' : 'N' : ''}</span>
    },
    filters: [
      {
        text: 'Yes',
        value: 1
      },
      {
        text: 'No',
        value: 0
      },
      {
        text: 'Blank',
        value: null
      },

    ],
    onFilter: (value, record) => record.SERUM_SAMPLE_TAKEN === value
  },
  {
    title: 'Verified By',
    dataIndex: 'SAMPLES_VERIFIED_BY',
    key: 'SAMPLES_VERIFIED_BY'
  },
]

const { RangePicker } = DatePicker

function LabOrderReport(props) {
  const [dateRange, setDateRange] = useState(undefined)
  const [ucn, setUCN] = useState('')
  const [labOrderReport, setLabOrderReport] = useState(undefined)
  const [openPlasmaSamples, setOpenPlasmaSamples] = useState(false);
  const [openSerumSamples, setOpenSerumSamples] = useState(false);
  const [scannedUCN, setScannedUCN] = useState('')
  const [ucnScansOk, setUCNScansOk] = useState(false);

  let printRef = useRef(null)
  let scanUCNRef = useRef(null)

  useEffect(() => {
    if (scanUCNRef.current) {
      scanUCNRef.current.focus();
    }
  }, [scanUCNRef.current])

  const handleDateRangeChange = (dates, dateStrings, info) => {
    const [fromdate, todate] = dateStrings
    if (fromdate != '' && todate != '') {
      setDateRange({
        fromDate: moment(fromdate).format('YYYY-MM-DD'),
        toDate: moment(todate).format('YYYY-MM-DD')
      })
    } else {
      setDateRange(undefined)
    }
  }

  const getLabOrderReport = () => {
    props.actions.setLoading()
    const body = {
      dateRange,
      ucn
    }
    const options = {
      method: 'post',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(body)
    }
    msalFetch('/api/reports/getLabOrder', options)
      .then(async res => {
        props.actions.clearLoading()
        const data = await res.json()
        if (res.ok) {
          const transformedData = data.map(x => {
            x.DONATION_DATE = formatDate(x.DONATION_DATE)
            x.SPE_RPR = x.SPE_RPR ? 'Y' : ''
            x.ATYA = x.ATYA ? 'Y' : ''
            x.NAT_VMT = x.NAT_VMT ? 'Y' : ''
            x.Lab_Result = x.Lab_Result ? x.Lab_Result : 'No Records'
            x.serum_sample_verified = x.SERUM_SAMPLE_TAKEN
            x.plasma_sample_verified = x.PLASMA_SAMPLE_TAKEN
            return x
          })
          setLabOrderReport(transformedData)
          setUCNScansOk(true)
        } else {
          toast.error('Failed to get lab order')
        }
      })
      .catch(err => {
        props.actions.clearLoading()
      })
  }

  const getPlasmaSamples = useMemo(() => {
    const filteredRecords = labOrderReport && labOrderReport.filter(x => (x.ATYA === 'Y' || x.NAT_VMT === 'Y'));
    return filteredRecords ? filteredRecords : [];
  }, [labOrderReport])

  const getSerumSamples = useMemo(() => {
    const filteredRecords = labOrderReport && labOrderReport.filter(x => (x.SPE_RPR === 'Y'));
    return filteredRecords ? filteredRecords : [];
  }, [labOrderReport])

  const scanPlasmaSample = (e) => {
    if (checkValidUCN(e.target.value)) {
      const newLaborder = labOrderReport.map(x => {
        if (x.UCN === e.target.value) {
          x.plasma_sample_verified = true;
        }
        return x
      })
      setLabOrderReport(newLaborder)
      setScannedUCN('')
    } else {
      setScannedUCN(e.target.value)
    }
  }

  const scanSerumSample = (e) => {
    if (checkValidUCN(e.target.value)) {
      const newLaborder = labOrderReport.map(x => {
        if (x.UCN === e.target.value) {
          x.serum_sample_verified = true;
        }
        return x
      })
      setLabOrderReport(newLaborder)
      setScannedUCN('')
    } else {
      setScannedUCN(e.target.value)
    }
  }

  const getRowClassName = (record) => {
    if (record.PLASMA_SAMPLE_TAKEN !== null || record.SERUM_SAMPLE_TAKEN !== null) {
      if ((record.ATYA === 'Y' || record.NAT_VMT === 'Y') && record.SPE_RPR) {
        if (record.serum_sample_verified && record.plasma_sample_verified) {
          return 'antdRowGreen'
        } else {
          return 'antdRowRed'
        }
      } else if (record.ATYA === 'Y' || record.NAT_VMT === 'Y') {
        if (record.plasma_sample_verified) {
          return 'antdRowGreen'
        } else {
          return 'antdRowRed'
        }
      } else if (record.SPE_RPR === 'Y') {
        if (record.serum_sample_verified) {
          return 'antdRowGreen'
        } else {
          return 'antdRowRed'
        }
      }
    } else {
      return null
    }
  }

  const handleOnUCNScansOk = () => {
    setUCNScansOk(true);
    setOpenPlasmaSamples(false);
    setOpenSerumSamples(false);
  }

  const saveSamplesVerification = () => {
    props.actions.setLoading()
    const records = labOrderReport.filter(x => x.serum_sample_verified || x.plasma_sample_verified).map(x => {
      let y = {};
      y.UCN = x.UCN;
      y.SERUM_SAMPLE_TAKEN = x.serum_sample_verified;
      y.PLASMA_SAMPLE_TAKEN = x.plasma_sample_verified;
      return y
    })
    const body = {
      records
    }
    const options = {
      method: 'post',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(body)
    }
    msalFetch('/api/reports/saveSamplesVerification', options).then(async res => {
      if (res.ok) {
        props.actions.clearLoading();
        toast.success('Data saved successfully');
      }
    }).catch(err => {
      props.actions.clearLoading()
    })
  }

  return (
    <div className='row justify-content-center mt-2'>
      <div className='col-md-12'>
        <div className='row d-flex justify-content-center mt-4 mb-4'>
          <div className='col-sm-10'>
            <h3 style={{ color: '#292764' }}>Lab Order Report</h3>
            <div className='row'>
              <div className='col-md-6'>
                Donation Date :{' '}
                <RangePicker
                  style={{ marginRight: '24px' }}
                  format='DD-MMM-YYYY'
                  onCalendarChange={handleDateRangeChange}
                ></RangePicker>
              </div>
              <div className='col-md-6'>
                UCN :
                <Input
                  style={{ width: '40%' }}
                  onChange={e => setUCN(e.target.value)}
                ></Input>
              </div>
            </div>
            <div className='my-2'>
              <Button type='primary' onClick={getLabOrderReport}>
                Apply
              </Button>
            </div>
            <div className='row my-4'>
              <div className='col-md-12'>
                {labOrderReport ? (
                  <>
                    <div className='d-flex justify-content-end mb-2'>
                      <Button type='primary' className='mx-1' onClick={() => { setOpenPlasmaSamples(true); setUCNScansOk(false) }}>Verify Plasma Samples</Button>
                      <Button type='primary' className='mx-1' onClick={() => { setOpenSerumSamples(true); setUCNScansOk(false) }}>Verify Serum Samples</Button>
                      <Button className='mx-1' onClick={saveSamplesVerification}>Save</Button>
                      <ReactToPrint
                        trigger={() => (
                          <Button icon={<DownloadOutlined />}>
                            PrintLabOrder
                          </Button>
                        )}
                        content={() => printRef}
                      />
                    </div>
                    <Table
                      columns={columns}
                      dataSource={labOrderReport}
                      size='small'
                      rowClassName={record => getRowClassName(record)}
                    ></Table>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <PrintLabOrder
          ref={el => {
            printRef = el
          }}
          labOrderReport={labOrderReport}
          ucn={ucn}
          donationRange={dateRange}
        ></PrintLabOrder>
      </div>
      <Modal
        open={openPlasmaSamples}
        onCancel={() => { setOpenPlasmaSamples(false); setUCNScansOk(false) }}
        cancelText='Close'
        title='Verify Plasma Samples'
        width={1000}
        onOk={handleOnUCNScansOk}
      >
        <div class="form-group row">
          <label htmlFor="scanucn" class="col-sm-2 col-form-label">Scan UCN:</label>
          <div class="col-sm-4">
            <Input ref={scanUCNRef} onChange={scanPlasmaSample} value={scannedUCN}></Input>
          </div>
        </div>
        <div className='mt-2'>
          <h6>Required Plasma Samples</h6>
          {getPlasmaSamples && getPlasmaSamples.map(x => {
            if (x.plasma_sample_verified) {
              return <Tag icon={<CheckCircleOutlined />} color="success"><span>{x.UCN}</span></Tag>
            }
            return <Tag icon={<ClockCircleOutlined />}><span>{x.UCN}</span></Tag>
          })}
        </div>
      </Modal>
      <Modal
        open={openSerumSamples}
        onCancel={() => { setOpenSerumSamples(false); setUCNScansOk(false) }}
        cancelText='Close'
        title='Verify Serum Samples'
        width={1000}
        onOk={handleOnUCNScansOk}
      >
        <div class="form-group row">
          <label htmlFor="scanucn" class="col-sm-2 col-form-label">Scan UCN:</label>
          <div class="col-sm-4">
            <Input ref={el => scanUCNRef.current = el} onChange={scanSerumSample} value={scannedUCN}></Input>
          </div>
        </div>
        <div>
          <h6>Required Serum Samples</h6>
          {getSerumSamples && getSerumSamples.map(x => {
            if (x.serum_sample_verified) {
              return <Tag icon={<CheckCircleOutlined />} color="success"><span>{x.UCN}</span></Tag>
            }
            return <Tag icon={<ClockCircleOutlined />}><span>{x.UCN}</span></Tag>
          })}
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LabOrderReport)
