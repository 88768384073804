import React, { useEffect, useState, useRef, useCallback } from 'react'
import styles from '../styles/DIS.module.css'
import { toast } from 'react-toastify'
import PrintReceipt from './PrintReceipt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faPrint } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { checkValidUCN, checkDonorEligibility } from '../utilities/validations'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { Input, Modal, Radio } from 'antd'
import { msalFetch } from '../utilities/msalFetch'
import { Form, Field, useForm, useFormState } from 'react-final-form'
import { useReactToPrint } from 'react-to-print'
import { Alert } from 'antd'

const MedicalScreener = props => {
  const [loading, setLoading] = useState(true)
  const [speOnlyWarnings, setSpeOnlyWarnings] = useState({})
  const history = useHistory()

  const [noDonationConfirmModalOpen, setNoDonationConfirmModalOpen] =
    useState(false)
  const [focused, setFocused] = useState({})
  const [showWeightPrompt, setShowWeightPrompt] = useState(false)
  const [confirmWeight, setConfirmWeight] = useState(null)
  const [noDonationModal, setNoDonationModal] = useState(false)
  const [speOnly, setSPEOnly] = useState(false)
  const [disableSpeOnlySelector, setDisableSpeOnlySelector] = useState(false)
  const formState = useFormState()
  const form = useForm()
  const [showPhySubConsellingModal, setShowPhySubConsellingModal] =
    useState(false)
  const [phySubReqComments, setPhySubReqComments] = useState('')
  const printRef = useRef(null)
  const weightRef = useRef(null)

  useEffect(() => {
    props.getData()
  }, [])

  useEffect(() => {
    if (props.drd != undefined) {
      setLoading(false)
      if (props.drd.spe_only) {
        setSPEOnly(true)
      }
    }
    if (
      props &&
      props.donorInfo.donor_status === 'TEMPORARILY DEFERRED (Abnormal SPE)' &&
      !sessionStorage.getItem('selectedUCN')
    ) {
      setSPEOnly(true)
      setDisableSpeOnlySelector(true)
    }
  }, [props])

  const checkDuplicateUCN = ucn => {
    const promise = new Promise((resolve, reject) => {
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        }
      }
      msalFetch(`api/donor/checkDuplicateUCN/${ucn}`, options)
        .then(async res => {
          if (res.ok) {
            resolve(res.json())
          } else {
            toast.error(res.error)
            reject(true)
          }
        })
        .catch(err => {
          toast.error(err)
        })
    })
    return promise
  }

  const validateUCN = async ucn => {
    if (formState.values.ucn !== ucn) {
      let error = undefined
      if (ucn) {
        if (!checkValidUCN(ucn)) {
          error = 'Invalid UCN'
        }
        if (await checkDuplicateUCN(ucn)) {
          error = 'Duplicate UCN'
        }
      } else {
        error = 'UCN is required'
      }
      return error
    }
    return undefined
  }

  const validateWeight = () => {
    let currentWeight = formState.values.weight
    if (
      currentWeight &&
      props.lastDonationWeight &&
      props.drd.weight !== currentWeight
    ) {
      if (
        props.lastDonationWeight >= 110 &&
        props.lastDonationWeight <= 149 &&
        parseInt(currentWeight) >= 150
      ) {
        setShowWeightPrompt(true)
      } else if (
        props.lastDonationWeight > 149 &&
        props.lastDonationWeight <= 174 &&
        parseInt(currentWeight) >= 175
      ) {
        setShowWeightPrompt(true)
      } else {
        showWeightPrompt && setShowWeightPrompt(false)
      }
    }
  }

  const disableUCN = () => {
    if (props.drd?.donor_id) {
      return true
    } else {
      if (props.formDisable) {
        return true
      } else {
        return false
      }
    }
  }

  const clearDonorQueueProcessing = donorId => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }
    msalFetch(`/api/reports/clearDonorProcessing/${donorId}`, options).then(
      async res => {
        if (res.ok) {
          console.log('cleared donor queue processing')
        } else {
          console.log('error in clearing donor processing')
        }
      }
    )
  }

  const handleExit = e => {
    e.preventDefault()
    if (!props.showForm) {
      clearDonorQueueProcessing(sessionStorage.getItem('selectedDonorId'))
      sessionStorage.removeItem('selectedUCN')
      sessionStorage.removeItem('selectedDonorId')
      history.push('/dashboard')
    } else {
      props.setShowForm(false)
    }
  }

  const renderOutOfRangeValues = () => {
    if (props.calculateOutofRanges(formState.values).length > 0) {
      return (
        <div>
          <p>Out of Range:</p>
          <table className='table table-striped table-danger table-sm'>
            <tbody>
              {props.calculateOutofRanges(formState.values).map(item => {
                return (
                  <tr key={item}>
                    <td style={{ fontSize: '14px', padding: '5px 10px' }}>
                      {item}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    }
    return null
  }

  const handleSpeOnlyDataChange = e => {
    setSpeOnlyWarnings({})
    props.setFormdata({ ...props.formData, [e.target.name]: e.target.value })
  }

  const disableTempRetake = () => {
    if (props.formDisable || !props.values.temp1) {
      return true
    } else {
      if (formState.values.temp1 >= 100.5) {
        return true
      } else {
        return false
      }
    }
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  })

  const renderContent = () => {
    if (props.donorInfo?.donor_id) {
      if (!props.hardStop.result) {
        return (
          <div className='row justify-content-center mt-4'>
            <div className='col-md-6 col-sm-8'>
              <Alert
                message='3X7 rule validation Failed'
                type='error'
                showIcon={true}
                description={props.hardStop?.message}
              ></Alert>
            </div>
          </div>
        )
      } else {
        const { eligible, reason } = checkDonorEligibility(
          props.donorInfo.dob,
          props.donorInfo.vein_grade_left,
          props.donorInfo.vein_grade_right,
          props.donorInfo.nddr_pass,
          props.donorInfo.address_verified,
          props.donorInfo.donor_status,
          props.dhqResult,
          props.drd.status,
          true
        )
        if (eligible) {
          if (props.showForm) {
            if (props.roleid !== 1 && !props.drd.weight && !props.drd.temp1) {
              return (
                <div className='row mt-4 d-flex justify-content-center'>
                  <div className='col-md-6 card'>
                    <div className='card-body'>
                      <h5 className='card-title'>
                        You must be logged in as Medical Screener to perform
                        this transaction
                      </h5>
                    </div>
                    <div className='card-footer'>
                      <button
                        className='btn btn-outline-danger btn-sm mx-2'
                        onClick={handleExit}
                      >
                        Exit
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
            return (
              <div className='row mt-5 mb-4 justify-content-between'>
                <div className='col-sm-6 offset-2 mb-4'>
                  <div className='row mb-3' style={{ fontWeight: '600' }}>
                    <div className={`col-md-5`}>SPE Only: </div>
                    <div className='col-md-6'>
                      <Radio.Group
                        onChange={e => setSPEOnly(e.target.value)}
                        value={speOnly}
                        disabled={props.formDisable || disableSpeOnlySelector}
                      >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  {speOnly ? (
                    <>
                      <div className='form-group row'>
                        <label
                          htmlFor='UCN'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          UCN <span style={{ color: 'red' }}>&#42;</span> :
                        </label>
                        <div className='col-sm-6'>
                          <input
                            type='text'
                            className='form-control form-control-sm'
                            id='UCN'
                            name='ucn'
                            placeholder='UCN'
                            value={props.formData.ucn}
                            pattern='LP\d{8}'
                            disabled={disableUCN()}
                            onChange={handleSpeOnlyDataChange}
                          />
                          {speOnlyWarnings.ucn ? (
                            <p className={styles.validationError}>
                              UCN is required
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className='form-group row'>
                        <label
                          htmlFor='TP'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          Total Protein (gm/dL)
                          <span style={{ color: 'red' }}>&#42;</span> :
                        </label>

                        <div className='col-sm-6'>
                          <input
                            type='number'
                            className='form-control form-control-sm'
                            id='tp'
                            placeholder='Total Protein'
                            name='total_protein'
                            disabled={props.formDisable}
                            onChange={handleSpeOnlyDataChange}
                            value={props.formData.total_protein}
                          />
                          {speOnlyWarnings.total_protein ? (
                            <p className={styles.validationError}>
                              Total Protein is required
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className='form-group row'>
                        <label
                          htmlFor='comments'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          Comments<span style={{ color: 'red' }}>&#42;</span> :
                        </label>

                        <div className='col-sm-6'>
                          <textarea
                            className='form-control form-control-sm'
                            id='screening_comments'
                            name='screening_comments'
                            rows={4}
                            disabled={disableUCN()}
                            onChange={handleSpeOnlyDataChange}
                            value={props.formData.screening_comments}
                          />
                          {speOnlyWarnings.screening_comments ? (
                            <p
                              className='text-danger'
                              style={{
                                fontSize: '14px',
                                padding: '0',
                                margin: '0'
                              }}
                            >
                              {speOnlyWarnings.screening_comments}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className='row d-flex justify-content-start'>
                        <div className='col-sm-12 d-flex justify-content-start'>
                          <button
                            className='btn btn-primary mt-4'
                            style={{
                              width: '90px',
                              height: '30px',
                              padding: '0px',
                              fontSize: '14px'
                            }}
                            disabled={props.formDisable}
                            onClick={handleSpeOnlyDonationSubmit}
                          >
                            Submit
                          </button>
                          <button
                            className='btn btn-danger mt-4 mx-2'
                            style={{
                              height: '30px',
                              width: '90px',
                              padding: '0px 5px',
                              fontSize: '14px'
                            }}
                            onClick={handleExit}
                          >
                            Exit
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <form onSubmit={props.handleSubmit}>
                      <div className='form-group row'>
                        <label
                          htmlFor='UCN'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          UCN <span style={{ color: 'red' }}>&#42;</span> :
                        </label>
                        <Field name='ucn' validate={validateUCN}>
                          {({ input, meta }) => (
                            <div className='col-sm-6'>
                              <input
                                type='text'
                                className='form-control form-control-sm'
                                id='UCN'
                                {...input}
                                placeholder='UCN'
                                pattern='LP\d{8}'
                                disabled={disableUCN()}
                              />
                              {formState.errors.ucn && meta.touched && (
                                <span className={styles.validationError}>
                                  {formState.errors.ucn}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className='form-group row'>
                        <label
                          htmlFor='cdds'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          CDCS InquiryID{' '}
                          <span style={{ color: 'red' }}>&#42;</span> :
                        </label>
                        <Field name='CDCS' validate={null}>
                          {({ input, meta }) => (
                            <div className='col-sm-6'>
                              <input
                                type='text'
                                className='form-control form-control-sm'
                                id='cdcs'
                                placeholder='CDCS Inquiry ID'
                                {...input}
                                disabled={props.formDisable}
                              />
                              {formState.errors.CDCS && meta.touched && (
                                <span className={styles.validationError}>
                                  {formState.errors.CDCS}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className='form-group row'>
                        <label
                          htmlFor='cdds_pass'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          CDCS Pass <span style={{ color: 'red' }}>&#42;</span>{' '}
                          :
                        </label>
                        <div className='col-sm-6'>
                          <div className='form-check form-check-inline'>
                            <Field
                              name='cdcs_check'
                              validate={null}
                              type='radio'
                            >
                              {({ input, meta }) => (
                                <input
                                  {...input}
                                  className='form-check-input'
                                  id='inlineRadio1'
                                  value='Yes'
                                  checked={props.values.cdcs_check === 'Yes'}
                                  disabled={props.formDisable}
                                />
                              )}
                            </Field>
                            <label
                              className='form-check-label'
                              htmlFor='inlineRadio1'
                            >
                              Yes
                            </label>
                          </div>
                          <div className='form-check form-check-inline'>
                            <Field
                              name='cdcs_check'
                              validate={null}
                              type='radio'
                            >
                              {({ input, meta }) => (
                                <input
                                  {...input}
                                  className='form-check-input'
                                  id='inlineRadio2'
                                  value='No'
                                  checked={props.values.cdcs_check === 'No'}
                                  disabled={props.formDisable}
                                />
                              )}
                            </Field>
                            <label
                              className='form-check-label'
                              htmlFor='inlineRadio2'
                            >
                              No
                            </label>
                          </div>
                          <br />
                          {formState.errors.cdcs_check &&
                            formState.touched.cdcs_check && (
                              <span className={styles.validationError}>
                                {formState.errors.cdcs_check}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className='form-group row'>
                        <label
                          htmlFor='weight'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          Weight (lbs)
                          <span style={{ color: 'red' }}>&#42;</span> :
                        </label>
                        <Field name='weight'>
                          {({ input, meta }) => (
                            <div className='col-sm-6'>
                              <input
                                type='number'
                                className='form-control form-control-sm'
                                id='weight'
                                placeholder='>=110lbs'
                                {...input}
                                disabled={props.formDisable}
                                pattern='^[-\d]\d*$'
                                min={0}
                                step={1}
                                ref={weightRef}
                                onBlur={validateWeight}
                              />
                              {formState.errors.weight && meta.touched && (
                                <span className={styles.validationError}>
                                  {formState.errors.weight}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className='form-group row'>
                        <label
                          htmlFor='armcheck'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          Arm Check <span style={{ color: 'red' }}>&#42;</span>{' '}
                          :
                        </label>
                        <Field name='arm_check' validate={null}>
                          {({ input, meta }) => (
                            <div className='col-sm-6'>
                              <select
                                className='form-select form-select-sm'
                                id='armcheck'
                                {...input}
                                disabled={props.formDisable}
                              >
                                <option selected disabled value=''>
                                  Select
                                </option>
                                <option value={'U'}>Unsatisfactory</option>
                                <option value={'S'}>Satisfactory</option>
                              </select>
                              {formState.errors.arm_check && meta.touched && (
                                <span className={styles.validationError}>
                                  {formState.errors.arm_check}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className='form-group row pb-2'>
                        <label
                          htmlFor='bp1'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          Blood Pressure (mmHg)
                          <span style={{ color: 'red' }}>&#42;</span> :
                        </label>
                        <div className='col-sm-3 d-flex'>
                          <Field name='bp_sys1' validate={null}>
                            {({ input, meta }) => (
                              <input
                                type='number'
                                className='form-control form-control-sm'
                                id='bp1sys'
                                placeholder='Sys'
                                disabled={props.formDisable}
                                {...input}
                                onFocus={() =>
                                  setFocused({ ...focused, bp_sys1: true })
                                }
                              />
                            )}
                          </Field>

                          <span className={styles.valueSepFont}>/</span>
                          <Field name='bp_dia1' validate={null}>
                            {({ input, meta }) => (
                              <input
                                type='number'
                                className='form-control form-control-sm'
                                id='bp1dia'
                                placeholder='Dia'
                                disabled={props.formDisable}
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        <div
                          className={`col-sm-1 d-flex justify-content-end align-items-center p-0 ${styles.drdLabel}`}
                        >
                          <span>Retake:</span>{' '}
                        </div>
                        <div className='col-sm-3 d-flex'>
                          <Field name='bp_sys2' validate={null}>
                            {({ input, meta }) => (
                              <input
                                type='number'
                                className='form-control form-control-sm'
                                id='bp2sys'
                                placeholder='Sys'
                                disabled={
                                  props.formDisable ||
                                  !props.values.bp_sys1 ||
                                  !props.values.bp_dia1
                                }
                                {...input}
                              />
                            )}
                          </Field>

                          <span className={styles.valueSepFont}>/</span>
                          <Field name='bp_dia2' validate={null}>
                            {({ input, meta }) => (
                              <input
                                type='number'
                                className='form-control form-control-sm'
                                id='bp1dia'
                                placeholder='Dia'
                                disabled={
                                  props.formDisable ||
                                  !props.values.bp_sys1 ||
                                  !props.values.bp_dia1
                                }
                                {...input}
                              />
                            )}
                          </Field>
                        </div>
                        {formState.errors.bp_sys1 &&
                          (formState.touched.bp_sys1 ||
                            formState.touched.bp_sys2 ||
                            formState.touched.bp_dia1) && (
                            <div className='col-md-6 offset-5'>
                              <span className={styles.validationError}>
                                {formState.errors.bp_sys1}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className='form-group row'>
                        <label
                          htmlFor='pulse'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          Pulse (bpm)<span style={{ color: 'red' }}>&#42;</span>{' '}
                          :
                        </label>
                        <Field name='pulse1' validate={null}>
                          {({ input, meta }) => (
                            <div className='col-sm-2'>
                              <input
                                type='number'
                                className='form-control form-control-sm'
                                id='pulse'
                                placeholder='50-100bpm'
                                disabled={props.formDisable}
                                {...input}
                                onFocus={() =>
                                  setFocused({ ...focused, pulse1: true })
                                }
                              />
                            </div>
                          )}
                        </Field>

                        <div
                          className={`col-sm-1 d-flex justify-content-end align-items-center p-0 ${styles.drdLabel}`}
                        >
                          <span>Retake:</span>{' '}
                        </div>
                        <Field name='pulse2' validate={null}>
                          {({ input, meta }) => (
                            <div className='col-sm-2'>
                              <input
                                type='number'
                                className='form-control form-control-sm'
                                id='pulse'
                                placeholder='50-100bpm'
                                disabled={
                                  props.formDisable || !props.values.pulse1
                                }
                                {...input}
                              />
                            </div>
                          )}
                        </Field>
                        {formState.errors.pulse1 && formState.touched.pulse1 && (
                          <div className='col-md-6 offset-5'>
                            <span className={styles.validationError}>
                              {formState.errors.pulse1}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className='form-group row'>
                        <label
                          htmlFor='temp'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          Temperature (F)
                          <span style={{ color: 'red' }}>&#42;</span> :
                        </label>
                        <Field name='temp1' validate={null}>
                          {({ input, meta }) => (
                            <div className='col-sm-2'>
                              <input
                                type='number'
                                className='form-control form-control-sm'
                                id='temp'
                                placeholder='96.0 - 99.5F'
                                name='temp1'
                                disabled={props.formDisable}
                                step='0.1'
                                {...input}
                                onFocus={() =>
                                  setFocused({ ...focused, temp1: true })
                                }
                              />
                            </div>
                          )}
                        </Field>

                        <div
                          className={`col-sm-1 d-flex justify-content-end align-items-center p-0 ${styles.drdLabel}`}
                        >
                          <span>Retake:</span>{' '}
                        </div>
                        <Field name='temp2' validate={null}>
                          {({ input, meta }) => (
                            <div className='col-sm-2'>
                              <input
                                type='number'
                                className='form-control form-control-sm'
                                id='temp'
                                placeholder='96.0 - 99.5F'
                                disabled={disableTempRetake()}
                                step='.01'
                                {...input}
                              />
                            </div>
                          )}
                        </Field>

                        {formState.errors.temp1 && formState.touched.temp1 && (
                          <div className='col-md-6 offset-5'>
                            <span className={styles.validationError}>
                              {formState.errors.temp1}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className='form-group row'>
                        <label
                          htmlFor='hct'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          Hematocrit (%)
                          <span style={{ color: 'red' }}>&#42;</span> :
                        </label>
                        <Field name='hct' validate={null}>
                          {({ input, meta }) => (
                            <div className='col-sm-6'>
                              <input
                                type='number'
                                className='form-control form-control-sm'
                                id='hct'
                                placeholder='Hematocrit'
                                {...input}
                                disabled={props.formDisable}
                              />
                              {formState.errors.hct && meta.touched && (
                                <span className={styles.validationError}>
                                  {formState.errors.hct}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className='form-group row'>
                        <label
                          htmlFor='TP'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          Total Protein (gm/dL)
                          <span style={{ color: 'red' }}>&#42;</span> :
                        </label>
                        <Field name='total_protein' validate={null}>
                          {({ input, meta }) => (
                            <div className='col-sm-6'>
                              <input
                                type='number'
                                className='form-control form-control-sm'
                                id='tp'
                                placeholder='Total Protein'
                                {...input}
                                disabled={props.formDisable}
                              />
                              {formState.errors.total_protein &&
                                meta.touched && (
                                  <span className={styles.validationError}>
                                    {formState.errors.total_protein}
                                  </span>
                                )}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className='form-group row'>
                        <label
                          htmlFor='comments'
                          className={`col-sm-5 col-form-label ${styles.drdLabel}`}
                        >
                          Comments :
                        </label>
                        <Field name='screening_comments' type='text-area'>
                          {({ input, meta }) => (
                            <div className='col-sm-6'>
                              <textarea
                                className='form-control form-control-sm'
                                id='comments'
                                disabled={props.formDisable}
                                {...input}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className='row d-flex justify-content-start'>
                        <div className='col-sm-12 d-flex justify-content-start'>
                          <button
                            className='btn btn-primary mt-4'
                            style={{
                              width: '90px',
                              height: '30px',
                              padding: '0px',
                              fontSize: '14px'
                            }}
                            type='submit'
                            disabled={props.formDisable}
                          >
                            Save
                          </button>
                          {props.drd.ucn && props.drd?.status === 'Pending' && (
                            <button
                              className='btn btn-warning mt-4 mx-2'
                              style={{
                                height: '30px',
                                fontSize: '14px',
                                padding: '0px 10px'
                              }}
                              onClick={e => {
                                e.preventDefault()
                                setNoDonationConfirmModalOpen(true)
                              }}
                            >
                              No Donation
                            </button>
                          )}
                          {props.drd.ucn && props.drd?.status === 'Pending' && (
                            <button
                              className='btn btn-primary mt-4 mx-2'
                              style={{
                                height: '30px',
                                fontSize: '14px',
                                padding: '0px 10px'
                              }}
                              onClick={e => {
                                e.preventDefault()
                                handlePhysubConsellingRequired()
                              }}
                            >
                              Counselling Required by PhySub
                            </button>
                          )}
                          <button
                            className='btn btn-danger mt-4 mx-2'
                            style={{
                              height: '30px',
                              width: '90px',
                              padding: '0px 5px',
                              fontSize: '14px'
                            }}
                            onClick={handleExit}
                          >
                            Exit
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
                <div className='col-md-3 offset-1'>
                  <p>Acceptable Ranges</p>
                  <table
                    className='table table-bordered'
                    style={{ fontSize: '12px' }}
                  >
                    <tbody>
                      <tr>
                        <th scope='row'>Weight</th>
                        <td>&gt;=110 lbs</td>
                      </tr>
                      <tr>
                        <th scope='row'>Blood Pressure</th>
                        <td>
                          Systolic : 90 - 180 mmHg<br></br>
                          Diastolic : 50 - 100 mmHg
                        </td>
                      </tr>
                      <tr>
                        <th scope='row'>Pulse</th>
                        <td>50 to 100 bpm</td>
                      </tr>
                      <tr>
                        <th scope='row'>Temperature</th>
                        <td>96.0F - 99.5F</td>
                      </tr>
                      <tr>
                        <th scope='row'>Total Protein(TP)</th>
                        <td>6gm/100ml - 9gm/100ml</td>
                      </tr>
                      <tr>
                        <th scope='row'>Hematocrit</th>
                        <td>
                          38%-54% htmlFor Women<br></br>
                          39%-54% htmlFor Men
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {renderOutOfRangeValues()}
                </div>
              </div>
            )
          } else {
            return (
              <div className='row mt-5 mb-4 pb-4 justify-content-center'>
                <div className='col-sm-6'>
                  <div className='card'>
                    <div className='card-header'>
                      Donation:
                      <h5 className='card-title'>{formState.values.ucn}</h5>
                    </div>
                    <div className='card-body'>
                      <div className='row p-0'>
                        <div className='col-sm-5'>
                          <table
                            className='table table-sm table-bordered'
                            style={{ fontSize: '14px' }}
                          >
                            {!props.drd.spe_only ? (
                              <tbody>
                                <tr>
                                  <td>CDCS Inquiry ID</td>
                                  <td>{formState.values.CDCS}</td>
                                </tr>
                                <tr>
                                  <td>CDCS Check</td>
                                  <td>{formState.values.cdcs_check}</td>
                                </tr>
                                <tr>
                                  <td>Weight</td>
                                  <td>{formState.values.weight} lbs</td>
                                </tr>
                                <tr>
                                  <td>Arm Check</td>
                                  <td>
                                    {formState.values.arm_check == 'S'
                                      ? 'Satisfactory'
                                      : 'Unsatisfactory'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Blood Pressure</td>
                                  <td>
                                    {formState.values.bp_sys1 +
                                      '/' +
                                      formState.values.bp_dia1}{' '}
                                    {formState.values.bp_sys2 &&
                                    formState.values.bp_dia2
                                      ? ',' +
                                        formState.values.bp_sys2 +
                                        '/' +
                                        formState.values.bp_dia2
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Temperature</td>
                                  <td>
                                    {formState.values.temp1} F{' '}
                                    {formState.values.temp2
                                      ? ',' + formState.values.temp2 + 'F'
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Pulse</td>
                                  <td>
                                    {formState.values.pulse1} bpm{' '}
                                    {formState.values.pulse2
                                      ? ',' + formState.values.pulse2 + 'bpm'
                                      : null}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Hematocrit</td>
                                  <td>{formState.values.hct}%</td>
                                </tr>
                                <tr>
                                  <td>Total Protein</td>
                                  <td>
                                    {formState.values.total_protein} gm/100ml
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                <td>Total Protein</td>
                                <td>
                                  {formState.values.total_protein} gm/100ml
                                </td>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                      {props.calculateOutofRanges(formState.values).length >
                      0 ? (
                        parseFloat(formState.values.temp1) >= 100.5 ||
                        parseFloat(formState.values.temp2) >= 100.5 ? (
                          <span className={styles.validationError}>
                            Donor cannot donate. Print this page and contact
                            Physician Substitute for health assessment as the
                            donor's temperature is 100.5F or above.
                          </span>
                        ) : (
                          <span className={styles.validationError}>
                            Cannot print Payment Receipt as screening values are
                            not in the range.
                          </span>
                        )
                      ) : null}
                      {checkIfDonorLost10Pounds(formState.values.weight) && (
                        <h5 className={styles.validationError}>
                          Donor has lost &gt;=10 lbs in the last 2 months and
                          requires a Physician Substitute Health Assessment
                        </h5>
                      )}
                    </div>
                    <div className='card-footer d-flex'>
                      <button
                        style={{
                          marginRight: '16px',
                          width: '80px',
                          height: '30px',
                          verticalAlign: 'center',
                          padding: '0'
                        }}
                        className='btn btn-primary mr-3'
                        onClick={() => props.setShowForm(true)}
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          style={{ marginRight: '5px' }}
                          disabled={props.drd?.status == 'QA Reviewed'}
                        />
                        Edit
                      </button>
                      {props.drd &&
                      ((props.drd.status == 'Pending' &&
                        props.drd.weight &&
                        props.drd.hct) ||
                        props.drd.spe_only) ? (
                        <button
                          className='btn mx-2 btn-success'
                          style={{
                            height: '30px',
                            padding: '0px 5px',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px'
                          }}
                          disabled={
                            props.calculateOutofRanges(props.drd).length
                          }
                          onClick={e => {
                            e.preventDefault()
                            handlePrint()
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPrint}
                            style={{ marginRight: '5px' }}
                          ></FontAwesomeIcon>
                          Print Receipt
                        </button>
                      ) : null}
                      {props.drd.ucn && props.drd?.status === 'Pending' && (
                        <button
                          className='btn btn-warning mx-2'
                          style={{
                            height: '30px',
                            fontSize: '14px',
                            padding: '0px 10px'
                          }}
                          onClick={e => {
                            e.preventDefault()
                            setNoDonationConfirmModalOpen(true)
                          }}
                        >
                          No Donation
                        </button>
                      )}
                      <button
                        className='btn btn-danger p-0 mx-2'
                        style={{
                          width: '10%',
                          height: '30px'
                        }}
                        onClick={handleExit}
                      >
                        Exit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        } else {
          return (
            <div className='row mt-4 d-flex justify-content-center'>
              <div className='col-md-6 card'>
                <div className='card-body'>
                  <h5 className='card-title'>
                    Donor does not meet basic eligibility criteria to Donate
                  </h5>
                  <p className='card-text text-danger'>{reason}</p>
                </div>
                <div className='card-footer'>
                  <button
                    className='btn btn-outline-danger btn-sm mx-2'
                    onClick={handleExit}
                  >
                    Exit
                  </button>
                </div>
              </div>
            </div>
          )
        }
      }
    }
  }

  const handleNoDonation = () => {
    props.actions.setLoading()
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({
        ucn: props.noDonationUCN,
        donor_id: props.donorId,
        formData: formState.values
      })
    }
    msalFetch(`api/donor/noDonation/Screening`, options)
      .then(async response => {
        props.actions.clearLoading()
        const data = await response.json()
        if (response.ok) {
          toast.success(data.message)
          setNoDonationModal(false)
          props.setNoDonationUCN('')
          setNoDonationConfirmModalOpen(false)
          history.push('/dashboard')
        } else {
          toast.error(data.message)
        }
      })
      .catch(err => {
        toast.error(err.message)
        props.actions.clearLoading()
      })
  }

  const handleSpeOnlyDonationSubmit = () => {
    const { ucn, total_protein, screening_comments, spe_taken } = props.formData
    if (!ucn) {
      setSpeOnlyWarnings({ ...speOnlyWarnings, ucn: 'UCN is required' })
    } else if (!total_protein) {
      setSpeOnlyWarnings({
        ...speOnlyWarnings,
        total_protein: 'Total Protein is required'
      })
    } else if (!screening_comments) {
      setSpeOnlyWarnings({
        ...speOnlyWarnings,
        screening_comments: 'Please enter comments'
      })
    } else {
      setSpeOnlyWarnings({})
      props.saveSpeSampleOnlyDonation()
    }
  }

  const checkIfDonorLost10Pounds = currentWeight => {
    if (props.weightLossHAExist) {
      return false
    } else {
      if (props.lastTwoMonthsWeights.length && currentWeight) {
        for (let i = 0; i < props.lastTwoMonthsWeights.length; i++) {
          if (
            parseInt(props.lastTwoMonthsWeights[i].weight) -
              parseInt(currentWeight) >=
            10
          ) {
            return true
          }
        }
        return false
      }
    }
  }

  const handlePhysubConsellingRequired = () => {
    if (formState.values.ucn && checkValidUCN(formState.values.ucn)) {
      setShowPhySubConsellingModal(true)
    } else {
      toast.error('UCN is required to request for PhySub counselling')
    }
  }

  const submitPhySubCousellingRequest = () => {
    const body = {
      donorid: props.donorInfo?.donor_id,
      ucn: formState.values.ucn,
      requestReason: phySubReqComments,
      assessmentType: 'Counselling'
    }
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(body)
    }
    props.actions.setLoading()
    msalFetch('/api/donor/requestHealthAssessment', options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          const data = await res.json()
          if (data.success) {
            toast.success(data.message)
            setPhySubReqComments('')
            setShowPhySubConsellingModal(false)
          } else {
            toast.error(data.message)
          }
        }
      })
      .catch(err => {
        props.actions.clearLoading()
      })
  }

  return (
    <React.Fragment>
      {loading ? (
        <div className='row mt-4 justify-content-center'>
          <div className='col-md-6 text-center'>Loading....</div>
        </div>
      ) : (
        renderContent()
      )}
      <Modal
        title=''
        open={noDonationModal}
        onOk={handleNoDonation}
        onCancel={() => setNoDonationModal(false)}
        okButtonProps={{
          disabled:
            props.noDonationUCN == '' || !checkValidUCN(props.noDonationUCN)
        }}
        centered
      >
        <p>Scan UCN to mark this as no donation</p>
        <Input
          placeholder='UCN'
          onChange={e => props.setNoDonationUCN(e.target.value)}
        ></Input>
      </Modal>
      <Modal
        title='Confirm'
        open={noDonationConfirmModalOpen}
        onOk={handleNoDonation}
        onCancel={() => setNoDonationConfirmModalOpen(false)}
        okButtonProps={{
          disabled:
            props.noDonationUCN == '' || !checkValidUCN(props.noDonationUCN)
        }}
        centered
      >
        <p>Are you sure this is a No Donation?</p>
      </Modal>
      <Modal
        title='! Please confirm weight once again'
        open={showWeightPrompt}
        onOk={() => {
          setShowWeightPrompt(false)
          form.change('weight', confirmWeight)
        }}
        centered
        okButtonProps={{ disabled: confirmWeight == null }}
        closeIcon={false}
        cancelButtonProps={{ disabled: true }}
      >
        <p>Last Donation Weight: {props.lastDonationWeight} lbs</p>
        <p>Current Weight {formState.values.weight} lbs</p>
        <Input
          placeholder='Weight'
          onChange={e => setConfirmWeight(e.target.value)}
          type='number'
        />
      </Modal>
      <Modal
        title='Request for Physican Sub Counselling'
        open={showPhySubConsellingModal}
        onOk={submitPhySubCousellingRequest}
        onCancel={() => {
          setShowPhySubConsellingModal(false)
          setPhySubReqComments('')
        }}
        centered
        okButtonProps={{ disabled: !phySubReqComments }}
      >
        <div class='form-group'>
          <label for='counsellingcomments'>Comments *</label>
          <textarea
            class='form-control'
            id='counsellingcomments'
            rows='3'
            maxLength={200}
            onChange={e => setPhySubReqComments(e.target.value)}
            value={phySubReqComments}
          ></textarea>
        </div>
      </Modal>
      <div style={{ display: 'none' }}>
        <PrintReceipt
          donorInfo={props.donorInfo}
          ref={printRef}
          drd={props.drd}
          dp={props.dp}
          speDue={props.speDue}
          healthAssessment={props.healthAssessment}
          screeningComments={formState.values.screening_comments}
          dhqComments={
            props.dhqData && props.dhqData[0] && props.dhqData[0].COMMENTS
          }
        ></PrintReceipt>
      </div>
    </React.Fragment>
  )
}

const FormWrappedComponent = props => {
  const [formData, setFormdata] = useState({})
  const [saveDisable, setSaveDisable] = useState(true)
  const [showForm, setShowForm] = useState(true)
  const [screeningCompleted, setScreeningCompleted] = useState(false)
  const [inEligibleFlag, setInEligibleFlag] = useState(false)
  const [formDisable, setFormDisable] = useState(false)
  const [cdcs_check, setCdcsPass] = useState(true)
  const [noDonationUCN, setNoDonationUCN] = useState('')
  const [showOutOfRangePrompt, setShowOutOfRangePromt] = useState(false)
  const [outofRangeValues, setOutOfRangeValues] = useState([])
  const [lastDonationWeight, setLastDonationWeight] = useState(undefined)
  const [lastTwoMonthsWeights, setLastTwoMonthsWeights] = useState([])
  const [weightLossHAExist, setWeightLossHAExist] = useState(false)

  const valuesRef = useRef()

  useEffect(() => {
    if (props.drd?.donor_id) {
      setFormdata(props.drd)
      setNoDonationUCN(props.drd.ucn)
      if (props.drd.qualified == 0) {
        setInEligibleFlag(true)
      } else {
        setInEligibleFlag(false)
      }
      if (props.drd.cdcs_check == 'No') {
        setCdcsPass(false)
      }
      if (
        props.drd.status == 'Pending QA Review' ||
        props.drd.status === 'QA Reviewed'
        //||props.drd.status === 'Quarantined'
      ) {
        setFormDisable(true)
      }
    } else {
      //setShowForm(true)
    }
  })

  const getLastTwoMonthsWeightLossInfo = donorID => {
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      }
    }
    msalFetch(`api/donor/getLastTwoMonthsWeightLossInfo/${donorID}`, options)
      .then(async res => {
        if (res.ok) {
          const { donations, weightLossHAExists = false } = await res.json()

          if (donations.length) {
            setLastDonationWeight(donations[0].weight)
            setLastTwoMonthsWeights(donations)
            setWeightLossHAExist(weightLossHAExists)
          } else {
            setLastDonationWeight(undefined)
          }
        } else {
          toast.error(res.error)
        }
      })
      .catch(err => {
        toast.error(err)
      })
  }

  useEffect(() => {
    props.donorId && getLastTwoMonthsWeightLossInfo(props.donorId)
  }, [props.donorId])

  const calculateOutofRanges = values => {
    let outOfRangeValues = []
    if (values.spe_only) return outOfRangeValues
    if (values.weight) {
      if (values.weight < 110) {
        outOfRangeValues.push('Weight')
      }
    }
    if (values.arm_check && values.arm_check === 'U') {
      outOfRangeValues.push('Arm Check')
    }
    if (values.pulse1) {
      if (values.pulse2) {
        if (
        /*   (values.pulse1 < 50 || values.pulse1 > 100) && 
            The above codition removed because when second set is out of range we dont consider the first set of readings
        */ 
          (values.pulse2 < 50 || values.pulse2 > 100)
        ) {
          outOfRangeValues.push('Pulse')
        }
      } else {
        if (values.pulse1 < 50 || values.pulse1 > 100) {
          outOfRangeValues.push('Pulse')
        }
      }
    }
    if (values.temp1) {
      if (values.temp2) {
        if (
         /*  (values.temp1 < 96 || values.temp1 > 99.5) && */
          (values.temp2 < 96 || values.temp2 > 99.5)
        ) {
          outOfRangeValues.push('Temperature')
        }
      } else {
        if (values.temp1 < 96 || values.temp1 > 99.5) {
          outOfRangeValues.push('Temperature')
        }
      }
    }

    if (values.cdcs_check && values.cdcs_check === 'No') {
      outOfRangeValues.push('CDCS Check')
    }

    if (values.hct) {
      if (props.donorInfo.gender == 'F') {
        if (values.hct < 38 || values.hct > 54) {
          outOfRangeValues.push('hct')
        }
      } else {
        if (values.hct < 39 || values.hct > 54) {
          outOfRangeValues.push('hct')
        }
      }
    }

    if (values.total_protein) {
      if (values.total_protein < 6 || values.total_protein > 9) {
        outOfRangeValues.push('Total Protein')
      }
    }

    if (values.bp_sys2 && values.bp_dia2) {
      if (
        (values.bp_sys1 > 180 ||
          values.bp_dia1 < 50 ||
          values.bp_dia1 > 100 ||
          values.bp_sys1 < 90) &&
        (values.bp_sys2 > 180 ||
          values.bp_dia2 < 50 ||
          values.bp_dia2 > 100 ||
          values.bp_sys2 < 90)
      ) {
        outOfRangeValues.push('Blood Pressure')
      }
    } else {
      if (values.bp_dia1 && values.bp_sys1) {
        if (
          values.bp_sys1 > 180 ||
          values.bp_dia1 < 50 ||
          values.bp_dia1 > 100 ||
          values.bp_sys1 < 90
        ) {
          outOfRangeValues.push('Blood Pressure')
        }
      }
    }
    return outOfRangeValues
  }

  const validate = values => {
    const errors = {}
    if (!values.ucn) {
      errors.ucn = 'UCN is required'
    }
    if (!values.CDCS) {
      errors.CDCS = 'CDCS Inquiry id is required'
    }
    if (!values.cdcs_check) {
      errors.cdcs_check = 'Please select CDCS Inquiry result'
    }
    if (values.weight) {
      if (values.weight >= 50 && values.weight <= 500) {
        if (values.weight < 110) {
          //errors.weight = "!Donor weight not in range"
        }
        if (toString(values.weight).split('.').length > 1) {
          errors.weight = 'Decimals are not allowed on weight'
        }
      } else {
        errors.weight = 'Invalid weight - Please verify once again'
      }
    } else {
      errors.weight = 'Donor weight is required'
    }

    if (!values.bp_sys1 || !values.bp_dia1) {
      errors.bp_sys1 = 'BP reading is required'
    }

    if (values.bp_sys1 && values.bp_dia1) {
      if (parseInt(values.bp_sys1) <= parseInt(values.bp_dia1)) {
        errors.bp_sys1 =
          'Invalid Blood Pressure - Systolic cannot be lesser or equal to diastolic'
        /* toast.error(
          'Invalid Blood Pressure - Systolic cannot be lesser or equal to diastolic'
        ) */
      }
    }

    if (values.bp_sys2 && values.bp_dia2) {
      if (parseInt(values.bp_sys2) <= parseInt(values.bp_dia2)) {
        errors.bp_sys1 =
          'Invalid Blood Pressure - Systolic cannot be lesser or equal to diastolic'
        /* toast.error(
          'Invalid Blood Pressure - Systolic cannot be lesser or equal to diastolic'
        ) */
      }
    }

    if (!values.pulse1) {
      errors.pulse1 = 'Pulse reading is required'
    }
    if (!values.temp1) {
      errors.temp1 = 'Temperature reading is required'
    }

    if (!values.total_protein) {
      errors.total_protein = 'Total Protein reading is required'
    }
    if (!values.hct) {
      errors.hct = 'Hematocrit reading is required'
    }

    if (!values.arm_check) {
      errors.arm_check = 'Please select Arm Check'
    }

    if ((values.bp_sys2 && values.bp_dia2) || values.temp2 || values.pulse2) {
      if (!values.pulse2) {
        errors.pulse1 = 'Please enter pulse retake'
      } else if (parseFloat(values.temp1) < 100.5 && !values.temp2) {
        errors.temp1 = 'Please enter temp retake'
      } else if (!values.bp_sys2 || !values.bp_dia2) {
        errors.bp_sys1 = 'Please enter Blood pressure retake'
      }
    }

    return errors
  }

  const saveScreeningData = values => {
    setShowOutOfRangePromt(false)
    let formValues = { ...values }
    if (calculateOutofRanges(values).length > 0) {
      formValues.qualified = false
    } else {
      formValues.qualified = true
    }

    valuesRef.current = { ...formValues }
    props.actions.setLoading()
    const data = {
      formData: { ...formValues },
      role: props.roleid
    }
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify(data)
    }
    msalFetch('api/donor/saveDRD', options)
      .then(async res => {
        const data = await res.json()
        if (res.status == 200) {
          setSaveDisable(true)
          setShowForm(false)
          toast.success(data.message)
          props.actions.clearLoading()
          setScreeningCompleted(true)
          props.getData()
        } else {
          setSaveDisable(false)
          toast.error(data.message)
          props.actions.clearLoading()
        }
      })
      .catch(err => {
        props.actions.clearLoading()
        toast.error(err.message)
      })
  }

  const handleSave = values => {
    if (calculateOutofRanges(values).length) {
      setShowOutOfRangePromt(true)
      setOutOfRangeValues(calculateOutofRanges(values))
      valuesRef.current = values
    } else {
      saveScreeningData(values)
    }
  }

  const saveSpeSampleOnlyDonation = () => {
    const data = {
      ucn: formData.ucn,
      total_protein: formData.total_protein,
      screening_comments: formData.screening_comments,
      donor_id: props.donorInfo.donor_id,
      page: 'medical_screening'
    }
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
    props.actions.setLoading()
    msalFetch('api/donor/saveSpeSampleOnlyDonation', options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          sessionStorage.setItem('selectedUCN', formData.ucn)
          const data = await res.json()
          toast.success(data.message)
          setShowForm(false)
          setScreeningCompleted(true)
          props.getData()
        } else {
          const data = await res.json()
          toast.error(data.message)
        }
      })
      .catch(err => {
        props.actions.clearLoading()
        toast.error(err.message)
      })
  }

  return (
    <>
      <Form
        onSubmit={handleSave}
        validate={validate}
        initialValues={{ ...formData }}
        mutators={{
          setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
            changeValue(state, fieldName, () => fieldVal)
          }
        }}
        render={({
          errors,
          handleSubmit,
          form,
          submitting,
          pristine,
          values
        }) => (
          <MedicalScreener
            {...props}
            errors={errors}
            handleSubmit={handleSubmit}
            values={values}
            submitting={submitting}
            pristine={pristine}
            form={form}
            saveDisable={saveDisable}
            screeningCompleted={screeningCompleted}
            showForm={showForm}
            setShowForm={setShowForm}
            formData={formData}
            setFormdata={setFormdata}
            inEligibleFlag={inEligibleFlag}
            setInEligibleFlag={setInEligibleFlag}
            formDisable={formDisable}
            setFormDisable={setFormDisable}
            cdcs_check={cdcs_check}
            setCdcsPass={setCdcsPass}
            noDonationUCN={noDonationUCN}
            setNoDonationUCN={setNoDonationUCN}
            calculateOutofRanges={calculateOutofRanges}
            lastDonationWeight={lastDonationWeight}
            saveSpeSampleOnlyDonation={saveSpeSampleOnlyDonation}
            lastTwoMonthsWeights={lastTwoMonthsWeights}
            weightLossHAExist={weightLossHAExist}
          />
        )}
      ></Form>
      <Modal
        open={showOutOfRangePrompt}
        centered
        closeIcon={false}
        okText='Continue'
        title={'! The following values are not in acceptable range.'}
        onCancel={() => setShowOutOfRangePromt(false)}
        onOk={() => saveScreeningData(valuesRef.current)}
      >
        <div>
          <p>Out of Range:</p>
          <table className='table table-striped table-danger table-sm'>
            <tbody>
              {outofRangeValues.map(item => {
                return (
                  <tr key={item}>
                    <td style={{ fontSize: '14px', padding: '5px 10px' }}>
                      {item}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(FormWrappedComponent)
