export const SET_LOGIN_CREDENTIALS='SET_LOGIN';
export const CLEAR_LOGIN='CLEAR_LOGIN';
export const SET_ISAUTHENTICATED = 'SET_ISAUTHENTICATED';
export const SET_ROLES_LIST = 'SET_ROLES_LIST';
export const SET_LOADING="SET_LOADING";
export const CLEAR_LOADING="CLEAR_LOADING";
export const SET_SELECTED_UCN="SET_SELECTED_UCN";
export const CLEAR_SELECTED_UCN="CLEAR_SELECTED_UCN";
export const SET_USER_ROLE="SET_USER_ROLE";
export const CLEAR_USER_ROLE="CLEAR_USER_ROLE";
export const SET_PAGE = 'SET_PAGE';
