import React, { useState, useEffect } from 'react'
import { msalFetch } from '../../utilities/msalFetch'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../../redux/actions'
import { bindActionCreators } from 'redux'
import { Badge, Button, DatePicker, Input, Table, Tag } from 'antd'
import moment from 'moment'
import { toast } from 'react-toastify'
import { formatDate } from '../../utilities/format'

const { RangePicker } = DatePicker

function HAReport (props) {
  const [records, setRecords] = useState([])
  const [filters, setFilters] = useState({})

  const history = useHistory()

  useEffect(() => {
    props.actions.setLoading()
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
      })
    }

    msalFetch('/api/donor/getHealthAssessments', options)
      .then(async res => {
        if (res.ok) {
          props.actions.clearLoading()
          const data = await res.json()
          setRecords(data)
        }
      })
      .catch(err => {
        console.log(err)
        props.actions.clearLoading()
      })
  }, [])

  const handleDateRangeChange = (dates, dateStrings, info) => {
    const [fromdate, todate] = dateStrings
    if (fromdate != '' && todate != '') {
      setFilters({
        ...filters,
        fromDate: moment(fromdate).format('YYYY-MM-DD'),
        toDate: moment(todate).format('YYYY-MM-DD')
      })
    }
  }

  const handleUCNChange = e => {
    setFilters({ ...filters, ucn: e.target.value })
  }

  const handleSelectRow = e => {
    sessionStorage.setItem('selectedDonorId', e)
    history.push('/donor/dis')
  }

  const validate = () => {
    if (filters.ucn) {
      return true
    } else {
      if (filters.fromDate && filters.toDate) {
        return true
      }
    }
    return false
  }

  const getHealthAssessments = () => {
    if (validate()) {
      props.actions.setLoading()
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...filters
        })
      }

      msalFetch('/api/donor/getHealthAssessments', options)
        .then(async res => {
          if (res.ok) {
            props.actions.clearLoading()
            const data = await res.json()
            setRecords(data)
          }
        })
        .catch(err => {
          console.log(err)
          props.actions.clearLoading()
        })
    } else {
      toast.error('Atleast one filter is required')
    }
  }

  const columns = [
    {
      title: 'Donor ID',
      key: 'DONORID',
      dataIndex: 'DONORID'
    },
    {
      title: 'UCN',
      key: 'UCN',
      dataIndex: 'UCN'
    },
    {
      title: 'Assessment Type',
      key: 'ASSESSMENT_TYPE',
      dataIndex: 'ASSESSMENT_TYPE',
      filters: [
        {
          text: 'Full Physical',
          value: 'Full Physical'
        },
        {
          text: 'Counselling',
          value: 'Counselling'
        }
      ],
      onFilter: (value, record) => record.ASSESSMENT_TYPE === value
    },
    {
      title: 'Status',
      key: 'STATUS',
      dataIndex: 'STATUS',
      filters: [
        {
          text: 'Complete',
          value: 'Complete'
        },
        {
          text: 'Cancelled',
          value: 'Cancelled'
        },
        {
          text: 'Pending',
          value: 'Pending'
        }
      ],
      onFilter: (value, record) => record.STATUS === value,
      render: (_, record) => {
        switch (record.STATUS) {
          case 'Complete':
            return <Tag color='green'>{record.STATUS}</Tag>
          case 'Cancelled':
            return <Tag color='red'>{record.STATUS}</Tag>
          case 'Pending':
            return <Tag color='blue'>{record.STATUS}</Tag>
        }
      }
    },
    {
      title: 'Assessment Date',
      key: 'DATE_OF_ASSESSMENT',
      dataIndex: 'DATE_OF_ASSESSMENT',
      render: (_, record) => {
        return <span>{formatDate(record.DATE_OF_ASSESSMENT)}</span>
      }
    },
    {
      title: 'Assessment Result',
      key: 'ASSESSMENT_RESULT',
      dataIndex: 'ASSESSMENT_RESULT',
      render: (_, record) => {
        if(record.ASSESSMENT_RESULT==='Pass') {
          return <Tag color='green'>{record.ASSESSMENT_RESULT}</Tag>
        } else if(record.ASSESSMENT_RESULT==='Fail') {
          return <Tag color='red'>{record.ASSESSMENT_RESULT}</Tag>
        } else {
          return <Tag color='orange'>Pending</Tag>
        }
      },
      filters: [
        {
          text: 'Pass',
          value: 'Pass'
        },
        {
          text: 'Fail',
          value: 'Fail'
        },
        {
          text: 'Pending',
          value: 'Pending'
        }
      ],
      onFilter: (value, record) => record.ASSESSMENT_RESULT === value,
    },
    {
      title: 'Donor Status',
      key: 'DONOR_STATUS',
      dataIndex: 'DONOR_STATUS'
    },
    {
      title: 'Phy Sub',
      key: 'UPLOADEDBY',
      dataIndex: 'UPLOADEDBY'
    }
  ]

  return (
    <div className='row justify-content-center mt-2'>
      <div className='col-md-10'>
        <div className='row d-flex mt-4 mb-4 d-flex'>
          <h3 style={{ color: '#292764' }}>Health Assessments</h3>
          <div className='col-md-5 col-sm-8'>
            <label>Date Range:</label>
            <RangePicker
              className='mx-2'
              format='DD-MMM-YYYY'
              onCalendarChange={handleDateRangeChange}
            />
          </div>
          <div className='col-md-6 col-sm-8'>
            <label className='mx-2'>UCN:</label>
            <Input style={{ width: '200px' }} onChange={handleUCNChange} />
            <Button
              type='primary'
              className='mx-2'
              onClick={getHealthAssessments}
            >
              Apply
            </Button>
          </div>
        </div>
        <div className='row d-flex mt-4'>
          <div className='col-md-12'>
            <Table dataSource={records} columns={columns} size='small' onRow={record => {
                  return {
                    onClick: e => {
                      handleSelectRow(record.DONORID)
                    }
                  }
                }}></Table>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}
const mapStateToProps = state => {
  return {
    loading: state.loading,
    roles: state.roles,
    roleId: state.roleId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HAReport)
