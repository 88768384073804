import React from 'react'
import { isParameterValid } from '../../utilities/validations'
import { formatDate, formatDateTime } from '../../utilities/format'

const MedicalScreening = props => {
  return (
    <div className='row mt-2 d-flex'>
      <div className='col-md-6'>
        {!props.donationDetails ? (
          <h4>Loading...</h4>
        ) : (
          <table className='table table-sm table-bordered'>
            <tbody>
              <tr>
                <td>Lab Name & Address</td>
                <th>Life Plasma, 5438 Perkiomen Ave, Reading PA 19606.</th>
              </tr>
              <tr>
                <td>UCN</td>
                <th>{props.donationDetails.ucn}</th>
              </tr>
              <tr>
                <td>Donation Date</td>
                <th>
                  {props.donationDetails.screening_time
                    ? formatDate(props.donationDetails.screening_time)
                    : formatDate(props.donationDetails.donation_date)}
                </th>
              </tr>
              <tr>
                <td>Screening Time</td>
                <th>
                  {props.donationDetails.screening_time
                    ? formatDateTime(props.donationDetails.screening_time)
                    : ''}
                </th>
              </tr>
              <tr>
                <td>CDCS Inquiry ID</td>
                <th>{props.donationDetails.CDCS}</th>
              </tr>
              <tr>
                <td>CDCS Check</td>
                <th
                  className={
                    props.donationDetails?.cdcs_check === 'No'
                      ? 'table-danger'
                      : null
                  }
                >
                  {props.donationDetails.CDCS
                    ? props.donationDetails.cdcs_check == 'Yes'
                      ? 'Passed'
                      : 'Failed'
                    : ''}
                </th>
              </tr>
              {props.donationDetails.nddr && (
                <>
                  <tr>
                    <td>NDDR Inquiry ID</td>
                    <th>{props.donationDetails.nddr}</th>
                  </tr>
                  <tr>
                    <td>NDDR Check</td>
                    <th
                      className={
                        props.donationDetails.nddr_check === 'Fail'
                          ? 'table-danger'
                          : null
                      }
                    >
                      {props.donationDetails.nddr_check == 'Pass'
                        ? 'Passed'
                        : 'Failed'}
                    </th>
                  </tr>
                </>
              )}
              <tr>
                <td>Weight (lbs)</td>
                <th
                  className={
                    props.donationDetails?.weight &&
                    !isParameterValid('WEIGHT', props.donationDetails?.weight)
                      ? 'table-danger'
                      : null
                  }
                >
                  {props.donationDetails.weight
                    ? props.donationDetails.weight
                    : null}
                </th>
              </tr>
              <tr>
                <td>Arm Check</td>
                <th
                  className={
                    props.donationDetails?.arm_check === 'U'
                      ? 'table-danger'
                      : null
                  }
                >
                  {props.donationDetails.arm_check
                    ? props.donationDetails.arm_check == 'S'
                      ? 'Satisfactory'
                      : 'Unsatisfactory'
                    : null}
                </th>
              </tr>
              <tr>
                <td>Blood Pressure (mmHg)</td>
                <th
                  className={
                    props.donationDetails?.bp_sys1 &&
                    !isParameterValid('BP', [
                      props.donationDetails?.bp_sys1,
                      props.donationDetails?.bp_dia1
                    ])
                      ? 'table-danger'
                      : null
                  }
                >
                  {props.donationDetails.bp_sys1
                    ? props.donationDetails.bp_sys1 +
                      '/' +
                      props.donationDetails.bp_dia1
                    : null}
                </th>
              </tr>
              {props.donationDetails.bp_sys2 > 0 ? (
                <tr>
                  <td>Blood Pressure (Retake) (mmHg)</td>
                  <th
                    className={
                      props.donationDetails?.bp_sys2 &&
                      !isParameterValid('BP_RETAKE', [
                        props.donationDetails?.bp_sys2,
                        props.donationDetails?.bp_dia2
                      ])
                        ? 'table-danger'
                        : null
                    }
                  >
                    {props.donationDetails.bp_sys2 +
                      '/' +
                      props.donationDetails.bp_dia2}
                  </th>
                </tr>
              ) : null}
              <tr>
                <td>Temperature (F)</td>
                <th
                  className={
                    props.donationDetails?.temp1 &&
                    !isParameterValid('TEMP', props.donationDetails?.temp1)
                      ? 'table-danger'
                      : null
                  }
                >
                  {props.donationDetails.temp1
                    ? props.donationDetails.temp1
                    : null}
                </th>
              </tr>
              {props.donationDetails.temp2 > 0 ? (
                <tr>
                  <td>Temperature (Retake)</td>
                  <th
                    className={
                      props.donationDetails?.temp2 &&
                      !isParameterValid('TEMP', props.donationDetails?.temp2)
                        ? 'table-danger'
                        : null
                    }
                  >
                    {props.donationDetails.temp2}
                  </th>
                </tr>
              ) : null}
              <tr>
                <td>Pulse (bpm)</td>
                <th
                  className={
                    props.donationDetails?.pulse1 &&
                    !isParameterValid('PULSE', props.donationDetails?.pulse1)
                      ? 'table-danger'
                      : null
                  }
                >
                  {props.donationDetails.pulse1
                    ? props.donationDetails.pulse1
                    : null}
                </th>
              </tr>
              {props.donationDetails.pulse2 > 0 ? (
                <tr>
                  <td>Pulse (Retake) (bpm)</td>
                  <th
                    className={
                      props.donationDetails?.pulse2 &&
                      !isParameterValid('PULSE', props.donationDetails?.pulse2)
                        ? 'table-danger'
                        : null
                    }
                  >
                    {props.donationDetails.pulse2}
                  </th>
                </tr>
              ) : null}
              <tr>
                <td>Total Protein (gm/dL)</td>
                <th
                  className={
                    props.donationDetails?.total_protein &&
                    !isParameterValid(
                      'TP',
                      props.donationDetails?.total_protein
                    )
                      ? 'table-danger'
                      : null
                  }
                >
                  {props.donationDetails.total_protein
                    ? props.donationDetails.total_protein
                    : null}
                </th>
              </tr>
              <tr>
                <td>Hematocrit (%)</td>
                <th
                  className={
                    props.donationDetails?.hct &&
                    !isParameterValid('Hemotacrit', [
                      props.donationDetails?.hct,
                      props.donorInfo.gender
                    ])
                      ? 'table-danger'
                      : null
                  }
                >
                  {props.donationDetails.hct ? props.donationDetails.hct : null}
                </th>
              </tr>
              <tr>
                <td>SPE Only Donation</td>
                <th>{props.donationDetails.spe_only ? 'Yes' : 'No'}</th>
              </tr>
              <tr>
                <td>Medical Screener</td>
                <th>
                  {props.donationDetails.medical_screener
                    ? props.donationDetails.medical_screener
                    : null}
                </th>
              </tr>
              <tr>
                <td>Comments</td>
                <th>{props.donationDetails.screening_comments}</th>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

export default MedicalScreening
