import React, { useState, useRef } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import { Input, DatePicker, Table, Button, Tag, Modal, Select } from 'antd'
import { formatDate } from '../utilities/format'
import { msalFetch } from '../utilities/msalFetch'
import styles from '../styles/DIS.module.css'

const { RangePicker } = DatePicker

function LabResultsReport (props) {
  const [dateRange, setDateRange] = useState(undefined)
  const [ucn, setUCN] = useState('')
  const [error, setError] = useState({})
  const [results, setResults] = useState([])
  const [selectedLabResultRow, setSelectedLabResultRow] = useState(null)
  const [openLabResultModal, setOpenLabResultModal] = useState(false)
  const [updateLabResultFormData, setUdateLabResultFormData] = useState({})
  const [updateLabResultLogs, setUpdateLabResultLogs] = useState([])

  const handleDateRangeChange = (dates, dateStrings, info) => {
    setError({})
    const [fromdate, todate] = dateStrings
    if (fromdate != '' && todate != '') {
      setDateRange({
        fromDate: moment(fromdate).format('YYYY-MM-DD'),
        toDate: moment(todate).format('YYYY-MM-DD')
      })
    } else {
      setDateRange(undefined)
    }
  }

  const validate = () => {
    if (!dateRange && !ucn) {
      toast.error('Please enter date range or ucn')
      setError({ error: 'Please enter date range or ucn' })
      return false
    } else {
      return true
    }
  }

  const fetchLabResults = () => {
    try {
      if (validate()) {
        const body = {
          ucn: ucn,
          dateRange
        }
        const options = {
          method: 'post',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: props.token
          },
          body: JSON.stringify(body)
        }
        props.actions.setLoading()
        msalFetch('/api/reports/getLabResultsReport', options)
          .then(async res => {
            props.actions.clearLoading()
            if (res.ok) {
              let data = await res.json()
              data = data.map(x => {
                x.donation_date = moment(x.donation_date).isValid()
                  ? formatDate(x.donation_date)
                  : ''
                x.SystemDate = moment(x.SystemDate).isValid()
                  ? formatDate(x.SystemDate)
                  : ''
                x.Approved = x.Approved_On && x.Approved_By ? 'Yes' : 'No'
                return x
              })
              setResults(data)
            } else {
              toast.error('Failed to fetch report')
              setResults(undefined)
            }
          })
          .catch(err => {
            props.actions.clearLoading()
            console.log(err)
          })
      }
    } catch (err) {
      props.actions.clearLoading()
      console.log(err)
    }
  }

  const fetchLabResultChangeLogs = ucn => {
    const options = {
      method: 'get',
      mode: 'cors'
    }
    msalFetch(`/api/donor/getChangeLogs/${ucn}/LAB_RESULT`, options).then(
      async res => {
        if (res.ok) {
          const data = await res.json()

          setUpdateLabResultLogs(data)
        } else {
          toast.error('Failed to fetch lab result change logs')
        }
      }
    )
  }

  const columns = [
    {
      title: 'UCN',
      dataIndex: 'UCN',
      key: 'UCN'
    },
    {
      title: 'Donation Date',
      dataIndex: 'donation_date',
      key: 'donation_date',
      sorter: (a, b) =>
        moment(b.donation_date).isValid
          ? moment(a.donation_date, 'MM/DD/YYYY').valueOf() -
            moment(b.donation_date, 'MM/DD/YYYY').valueOf()
          : moment(a.donation_date).valueOf() -
            moment('12/12/9999', 'MM/DD/YYYY')
    },
    {
      title: 'Upload Date',
      dataIndex: 'SystemDate',
      key: 'SystemDate',
      sorter: (a, b) =>
        moment(b.SystemDate).isValid
          ? moment(a.SystemDate, 'MM/DD/YYYY').valueOf() -
            moment(b.SystemDate, 'MM/DD/YYYY').valueOf()
          : moment(a.SystemDate).valueOf() - moment('12/12/9999', 'MM/DD/YYYY')
    },
    {
      title: 'Result',
      dataIndex: 'Lab_Result',
      key: 'Lab_Result',
      render: (_, { Lab_Result }) => {
        switch (Lab_Result) {
          case 'Partial':
            return <Tag color='geekblue'>{Lab_Result}</Tag>
          case 'Pass':
            return <Tag color='green'>{Lab_Result}</Tag>
          case 'Reactive':
            return <Tag color='red'>{Lab_Result}</Tag>
          case 'Abnormal SPE':
            return <Tag color='yellow'>{Lab_Result}</Tag>
          case 'RPR Positive':
            return <Tag color='yellow'>{Lab_Result}</Tag>
          case 'ATYA Positive':
            return <Tag color='red'>{Lab_Result}</Tag>
          case 'No Records':
            return <p>{Lab_Result}</p>
          case null:
            return <p>No Records</p>
        }
      },
      filters: [
        {
          text: 'Partial',
          value: 'Partial'
        },
        {
          text: 'Pass',
          value: 'Pass'
        },
        {
          text: 'Reactive',
          value: 'Reactive'
        },
        {
          text: 'Abnormal SPE',
          value: 'Abnormal SPE'
        },
        {
          text: 'ATYA Positive',
          value: 'ATYA Positive'
        },
        {
          text: 'RPR Positive',
          value: 'RPR Positive'
        },
        {
          text: 'No Records',
          value: 'No Records'
        }
      ],
      onFilter: (value, record) => record.Lab_Result.indexOf(value) === 0
    },
    {
      title: 'SPE Taken',
      dataIndex: 'spe_taken',
      key: 'spe_taken',
      render: (_, { spe_taken }) => {
        return <span>{spe_taken ? 'Yes' : 'No'}</span>
      },
      filters: [
        {
          text: 'Yes',
          value: 1
        },
        {
          text: 'No',
          value: 0
        }
      ],
      onFilter: (value, record) => {
        return record.spe_taken === value
      }
    },
    {
      title: 'Physician Substitute Review',
      children: [
        {
          title: 'Physician Substitute',
          dataIndex: 'physub_review',
          key: 'physub_review',
          filters: [
            { value: 'Reviewed', text: 'Reviewed' },
            { value: 'Not Reviewed', text: 'Not Reviewed' }
          ],
          onFilter: (value, record) => {
            if (value === 'Reviewed') {
              return !!record.physub_review
            } else {
              return !record.physub_review
            }
          }
        },
        {
          title: 'PhySub Review Date',
          dataIndex: 'physub_review_date',
          key: 'physub_review_date',
          render: (_, { physub_review_date }) => {
            return <p>{formatDate(physub_review_date)}</p>
          }
        }
      ]
    },
    {
      title: 'Quality Assurance Review',
      children: [
        {
          title: 'Quality Assurance',
          dataIndex: 'qa_review',
          key: 'qa_review',
          filters: [
            { value: 'Reviewed', text: 'Reviewed' },
            { value: 'Not Reviewed', text: 'Not Reviewed' }
          ],
          onFilter: (value, record) => {
            if (value === 'Reviewed') {
              return !!record.qa_review
            } else {
              return !record.qa_review
            }
          }
        },
        {
          title: 'QA Review Date',
          dataIndex: 'qa_review_date',
          key: 'qa_review_date',
          render: (_, { qa_review_date }) => {
            return <p>{formatDate(qa_review_date)}</p>
          }
        }
      ]
    }
  ]

  const checkAlbuminOutofRange = albumin => {
    if (albumin >= 3.01 && albumin <= 5.95) {
      return false
    }
    return true
  }

  const checkAlpha1OutofRange = alpha1 => {
    if (alpha1 >= 0.17 && alpha1 <= 0.44) {
      return false
    }
    return true
  }

  const checkAlpha2OutofRange = alpha2 => {
    if (alpha2 >= 0.42 && alpha2 <= 1.06) {
      return false
    }
    return true
  }

  const checkBetaOutofRange = beta => {
    if (beta >= 0.5 && beta <= 1.17) {
      return false
    }
    return true
  }

  const checkGammaOutofRange = gamma => {
    if (gamma >= 0.4 && gamma <= 1.8) {
      return false
    }
    return true
  }

  const checkTPOutofRange = tp => {
    if (tp >= 6.0 && tp <= 9.0) {
      return false
    }
    return true
  }

  const viralMarkersOutOfRange = x => {
    if (x == 'N') {
      return false
    }
    return true
  }

  const updateLabResult = () => {
    try {
      props.actions.setLoading()
      const body = {
        ucn: selectedLabResultRow.UCN,
        labResult: updateLabResultFormData.labResult,
        comments: updateLabResultFormData.comments
      }
      const options = {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: props.token
        },
        body: JSON.stringify(body)
      }
      msalFetch('/api/reports/updateLabResult', options)
        .then(async res => {
          props.actions.clearLoading()
          if (res.ok) {
            toast.success('Lab Result Updated successfully')
            setSelectedLabResultRow(null)
            setOpenLabResultModal(false)
            fetchLabResults()
          } else {
            const data = await res.json()
            toast.error(data.message)
          }
        })
        .catch(e => {
          props.actions.clearLoading()
        })
    } catch (e) {
      console.log(e)
    }
  }

  const handleUpdateLabResultChange = e => {
    setUdateLabResultFormData({
      ...updateLabResultFormData,
      [e.target.name]: e.target.value
    })
  }

  const renderLabResults = selectedLabResultRow => {
    let required = []
    const parameters = {
      Albumin: {
        key: 'Albumin',
        group: 'Serum Protein Electrophoresis',
        validator: checkAlbuminOutofRange,
        title: 'Albumin',
        expected: '3.01-5.95 g/dL'
      },
      Alpha1: {
        key: 'Alpha1',
        group: 'Serum Protein Electrophoresis',
        validator: checkAlpha1OutofRange,
        title: 'Alpha-1',
        expected: '0.17-0.44 g/dL'
      },
      Alpha2: {
        key: 'Alpha2',
        group: 'Serum Protein Electrophoresis',
        validator: checkAlpha2OutofRange,
        title: 'Alpha-2',
        expected: '0.42-1.06 g/dL'
      },
      Beta: {
        key: 'Beta',
        group: 'Serum Protein Electrophoresis',
        validator: checkBetaOutofRange,
        title: 'Beta Globulins',
        expected: '0.50-1.17 g/dL'
      },
      Gamma: {
        key: 'Gamma',
        group: 'Serum Protein Electrophoresis',
        validator: checkGammaOutofRange,
        title: 'Gamma',
        expected: '0.40-1.80 g/dL'
      },
      RPR: {
        key: 'RPR',
        group: 'RPR (Syphilis Screen)',
        validator: viralMarkersOutOfRange,
        title: 'RPR',
        expected: 'N'
      },
      TP: {
        key: 'TP',
        group: 'Total Protein',
        validator: checkTPOutofRange,
        title: 'TP',
        expected: '6.0-9.0 g/dL'
      },
      HBsAg: {
        key: 'HBsAg',
        group: 'Viral Markers',
        validator: viralMarkersOutOfRange,
        title: 'Hepatitis B Surface Antigen',
        expected: 'N'
      },
      HCV: {
        key: 'HCV',
        group: 'Viral Markers',
        validator: viralMarkersOutOfRange,
        title: 'Hepatitis C Antibody',
        expected: 'N'
      },
      HIV: {
        key: 'HIV',
        group: 'Viral Markers',
        validator: viralMarkersOutOfRange,
        title: 'HIV 1/2 plus 0',
        expected: 'N'
      },
      HBVDNA: {
        key: 'HBVDNA',
        group: 'NAT',
        validator: viralMarkersOutOfRange,
        title: 'HBV DNA',
        expected: 'N'
      },
      HCVRNA: {
        key: 'HCVRNA',
        group: 'NAT',
        validator: viralMarkersOutOfRange,
        title: 'HCV RNA',
        expected: 'N'
      },
      HIVRNA: {
        key: 'HIVRNA',
        group: 'NAT',
        validator: viralMarkersOutOfRange,
        title: 'HIV RNA',
        expected: 'N'
      },
      HAVRNA: {
        key: 'HAVRNA',
        group: 'NAT',
        validator: viralMarkersOutOfRange,
        title: 'HAV RNA',
        expected: 'N'
      },
      B19DNA: {
        key: 'B19DNA',
        group: 'NAT',
        validator: viralMarkersOutOfRange,
        title: 'Parvo B19 DNA',
        expected: 'N'
      },
      ABS: {
        key: 'ABS',
        group: 'Antibody Screen',
        validator: viralMarkersOutOfRange,
        title: 'Antibody Screen',
        expected: 'N'
      }
    }
    const {
      Albumin,
      Alpha1,
      Alpha2,
      Beta,
      Gamma,
      RPR,
      TP,
      HBsAg,
      HCV,
      HIV,
      HBVDNA,
      HCVRNA,
      HIVRNA,
      HAVRNA,
      B19DNA,
      ABS
    } = parameters
    const speParameters = [Albumin, Alpha1, Alpha2, Beta, Gamma, RPR, TP]
    if (selectedLabResultRow?.spe_only) {
      required = [Albumin, Alpha1, Alpha2, Beta, Gamma, RPR, TP]
    } else if (selectedLabResultRow?.donor_type === 'APPLICANT 1') {
      required = [
        Albumin,
        Alpha1,
        Alpha2,
        Beta,
        Gamma,
        RPR,
        TP,
        HBsAg,
        HCV,
        HIV,
        HBVDNA,
        HCVRNA,
        HIVRNA,
        HAVRNA,
        B19DNA,
        ABS
      ]
    } else if (
      selectedLabResultRow?.donor_type === 'APPLICANT 2' ||
      selectedLabResultRow?.donor_type === 'QUALIFIED'
    ) {
      if (selectedLabResultRow?.spe_taken) {
        required = [
          Albumin,
          Alpha1,
          Alpha2,
          Beta,
          Gamma,
          RPR,
          TP,
          HBsAg,
          HCV,
          HIV,
          HBVDNA,
          HCVRNA,
          HIVRNA,
          HAVRNA,
          B19DNA,
          ABS
        ]
      } else {
        required = [HAVRNA, HBVDNA, HCVRNA, HBsAg, HCV, HIV, HIVRNA, B19DNA]
      }
    } else {
      required = [HAVRNA, HBVDNA, HCVRNA, HBsAg, HCV, HIV, HIVRNA, B19DNA]
    }
    //adding missing params if we got them from Lab
    speParameters.forEach(x => {
      if (
        required.findIndex(y => y.key == x.key) == -1 &&
        selectedLabResultRow[x.key]
      ) {
        required.push(x)
      }
    })
    if (Object.keys(selectedLabResultRow).length && selectedLabResultRow) {
      return (
        <tbody>
          {required.map((x, i) => {
            return (
              <tr key={i}>
                <td>{x.group}</td>
                <td>{x.title}</td>
                <td
                  className={
                    selectedLabResultRow[x.key]
                      ? x.validator(selectedLabResultRow[x.key])
                        ? 'table-danger'
                        : 'table-success'
                      : ''
                  }
                >
                  {selectedLabResultRow[x.key]
                    ? selectedLabResultRow[x.key]
                    : ''}
                </td>
                <td>{x.expected}</td>
              </tr>
            )
          })}
        </tbody>
      )
    }
    return 'Loading...'
  }

  const LabReport = () => {
    return (
      <React.Fragment>
        {selectedLabResultRow ? (
          Object.keys(selectedLabResultRow).length ? (
            <div>
              <table className='table table-borderless table-sm'>
                <tbody>
                  <tr>
                    <td>UCN :</td>
                    <th>{selectedLabResultRow.UCN}</th>
                    <td>Draw Date :</td>
                    <th>{formatDate(selectedLabResultRow.DrawDate)}</th>
                  </tr>
                  {selectedLabResultRow &&
                  selectedLabResultRow.physub_review ? (
                    <tr>
                      <td>Physician Substitute :</td>
                      <th>{selectedLabResultRow.physub_review}</th>
                      <td>PhySub Review Date :</td>
                      <th>
                        {formatDate(selectedLabResultRow.physub_review_date)}
                      </th>
                    </tr>
                  ) : null}
                  {selectedLabResultRow && selectedLabResultRow.qa_review ? (
                    <tr>
                      <td>Quality Assurance :</td>
                      <th>{selectedLabResultRow.qa_review}</th>
                      <td>QA Review Date :</td>
                      <th>{formatDate(selectedLabResultRow.qa_review_date)}</th>
                    </tr>
                  ) : null}
                  <tr>
                    <td>LAB Name :</td>
                    <th>GC Tech Lab</th>
                    <td>Address :</td>
                    <th>485 Spencer Lane, San Antonio, TX 78201</th>
                  </tr>
                  <tr>
                    <td>Upload Date :</td>
                    <th>{formatDate(selectedLabResultRow?.SystemDate)}</th>
                  </tr>
                </tbody>
              </table>
              <table className='table table-bordered table-sm'>
                <thead className={styles.labresultsheader}>
                  <tr>
                    <td>Type</td>
                    <td>Flag</td>
                    <td>Result</td>
                    <td>Reference Range</td>
                  </tr>
                </thead>
                {renderLabResults(selectedLabResultRow)}
              </table>
            </div>
          ) : (
            <h4>No Records found</h4>
          )
        ) : (
          <h4>Loading...</h4>
        )}
      </React.Fragment>
    )
  }

  const renderApproveButton = () => {
    if (
      props.roleid === 7 &&
      selectedLabResultRow &&
      !selectedLabResultRow.speOnly
    ) {
      return (
        <Button type='primary' onClick={reviewLabReport}>
          Physician Sub Review
        </Button>
      )
    } else if (props.roleid == 3 && selectedLabResultRow) {
      return (
        <Button type='primary' onClick={reviewLabReport}>
          QA Review
        </Button>
      )
    } else {
      return null
    }
  }

  const reviewLabReport = () => {
    props.actions.setLoading()
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: props.token
      },
      body: JSON.stringify({
        UCN: selectedLabResultRow.UCN,
        reviewerRoleId: props.roleid
      })
    }
    msalFetch('/api/donor/reviewLabReport', options)
      .then(async res => {
        props.actions.clearLoading()
        if (res.ok) {
          const data = await res.json()
          if (data.success) {
            toast.success('Lab result approved')
            setSelectedLabResultRow({
              ...selectedLabResultRow,
              ...data
            })
            fetchLabResults()
          }
        } else {
          props.actions.clearLoading()
          toast.error('Failed to approve')
        }
      })
      .catch(err => {
        props.actions.clearLoading()
        toast.error('Failed to approve')
      })
  }

  return (
    <div className='row justify-content-center mt-4'>
      <div className='col-md-10'>
        <div className='row'>
          <h2 style={{ color: '#292764' }}>Lab Results Report</h2>
          <div className='row mt-3'>
            <div className='col-md-2'>
              <label htmlFor='daterange'>Donation Date Range :</label>
            </div>
            <div className='col-md-3'>
              <RangePicker
                format='MM-DD-YYYY'
                onCalendarChange={handleDateRangeChange}
              ></RangePicker>
            </div>
            <div className='col-md-1'>
              {' '}
              <label htmlFor='ucn'>UCN :</label>
            </div>
            <div className='col-md-2'>
              <Input
                id='ucn'
                placeholder='UCN'
                onChange={e => {
                  setUCN(e.target.value)
                  setError({})
                }}
              ></Input>
            </div>
            <div className='col-md-2'>
              <Button type='primary' onClick={fetchLabResults}>
                Apply
              </Button>
            </div>
            {error.error ? (
              <p className='col-md-6 text-danger'>{error.error}</p>
            ) : null}
          </div>
        </div>
        <div className='row mt-4'>
          {results && (
            <Table
              columns={columns}
              dataSource={results}
              size='middle'
              onRow={record => {
                return {
                  onClick: e => {
                    setOpenLabResultModal(true)
                    setSelectedLabResultRow(record)
                    setUdateLabResultFormData({})
                    fetchLabResultChangeLogs(record.UCN)
                  }
                }
              }}
            ></Table>
          )}
        </div>
      </div>
      <Modal
        open={openLabResultModal}
        onCancel={() => {
          setOpenLabResultModal(false)
          setUpdateLabResultLogs([])
          setSelectedLabResultRow(null)
        }}
        width={1200}
        footer={null}
        centered
        styles={{
          body: {
            overflowY: 'scroll',
            maxHeight: '80vh',
            padding: '16px'
          }
        }}
      >
        <LabReport></LabReport>
        <div className='row mt-2 d-flex justify-content-between'>
          <div className='col-md-6'>
            {props.roleid === 3 ? (
              <div className='row'>
                <div className='col-md-6'>
                  <label htmlFor='updateLabRsult'>Update Lab Result : </label>
                  <select
                    className='form-select form-select-sm'
                    aria-label='select'
                    onChange={handleUpdateLabResultChange}
                    value={
                      updateLabResultFormData.labResult
                        ? updateLabResultFormData.labResult
                        : 'Select'
                    }
                    name='labResult'
                  >
                    <option selected disabled>
                      Select
                    </option>
                    <option value='Pass'>Pass</option>
                    <option value='Reactive'>Reactive</option>
                    <option value='RPR Positive'>RPR Positive</option>
                    <option value='Abnormal SPE'>Abnormal SPE</option>
                    <option value='ATYA Positive'>ATYA Positive</option>
                    <option value='Partial'>Partial</option>
                  </select>
                  <label htmlFor='comments'>Comments :</label>
                  <textarea
                    className='form-control form-control-sm'
                    id='comments'
                    rows='3'
                    onChange={handleUpdateLabResultChange}
                    name='comments'
                    value={
                      updateLabResultFormData.comments
                        ? updateLabResultFormData.comments
                        : ''
                    }
                  ></textarea>
                  <Button
                    type='primary'
                    disabled={!updateLabResultFormData.labResult}
                    onClick={updateLabResult}
                    className='mt-2'
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : null}
            <div className='mt-2'>
              <h5>Lab Result Update Log</h5>
              <table className='table table-sm table-bordered'>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Old Value</th>
                    <th>New Value</th>
                    <th>Date</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {updateLabResultLogs.map(x => {
                    return (
                      <tr key={x.change_id}>
                        <td>{x.user}</td>
                        <td>{x.old_value}</td>
                        <td>{x.new_value}</td>
                        <td>{formatDate(x.datetime)}</td>
                        <td>{x.change_info}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-2 d-flex justify-content-end'>
            {renderApproveButton()}
          </div>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    username: state.username,
    token: state.token,
    roleid: state.roleid
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LabResultsReport)
